import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Select, MenuItem, TextField, Button, FormControl, InputLabel } from '@mui/material';
import { addCompany, getAllChildCompany } from '../utils/api.home';
import { successMessage } from '../common/Toast';
import { MdAdd } from "react-icons/md";

const AddCompany = () => {
  const [companyType, setCompanyType] = useState('parent');
  const [name, setName] = useState('');
  const [companySelected, setCompanySelected] = useState('');
  const [company_list, setCompanyList] = useState([])

  useEffect(() => {
    (async () => {
      try {
        const response = await getAllChildCompany();
        response?.data?.data && setCompanyList(response.data.data)
      } catch (error) {
        console.log(error);
      }
    })()
  }, [])

  const handleCompanyTypeChange = (event) => {
    setCompanyType(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleCompanySelectedChange = (event) => {
    setCompanySelected(event.target.value);
  };

  const addNewCompany = async () => {
    try {
      const data = {
        name,
        company_type: companyType,
        parent_company: companyType === 'parent' ? null : companySelected
      }
      const response = await addCompany(data);
      if (response.status === 200) {
        successMessage(response.data.message)
        clear()
      }
      console.log(response, "000");
    } catch (error) {
      console.log(error);
    }
  };

  const clear = () => {
    setName('')
    setCompanyType('parent')
    setCompanySelected('')
  }

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" className='m-t-20'><b>Add Company</b></Typography>
        </Grid>
        <Grid item xs={12}>
          {/* <Typography variant="h6">Company Type:</Typography> */}
          <FormControl fullWidth className='m-t-5'>
            <InputLabel id="company-type-label">Company Type</InputLabel>
            <Select
              labelId="company-type-label"
              value={companyType}
              onChange={handleCompanyTypeChange}
              label="Company Type"
            >
              <MenuItem value="parent">Parent</MenuItem>
              <MenuItem value="child">Child</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {/* <Typography variant="h6">Name:</Typography> */}
          <TextField
            className='m-t-5'
            fullWidth
            variant="outlined"
            value={name}
            onChange={handleNameChange}
            placeholder="Company Name"
          />
        </Grid>
        <Grid item xs={12}>
          {/* <Typography variant="h6">Parent Company:</Typography> */}
          <FormControl fullWidth className='m-t-5'>
            <InputLabel id="parent-company-label">Parent Company</InputLabel>
            <Select
              labelId="parent-company-label"
              value={companyType === 'parent' ? '' : companySelected}
              onChange={handleCompanySelectedChange}
              disabled={companyType === 'parent'}
              label="Parent Company"
            >
              <MenuItem value="">Select</MenuItem>
              {company_list && company_list.map((company, index) => (
                <MenuItem key={index} value={company}>
                  {company}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} textAlign="center" className='w-100'>
          <Button variant="contained" size="large" startIcon={<MdAdd />}  onClick={addNewCompany}>
            Add Company
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddCompany