import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { getOneCompanyDetails } from '../../utils/api.home';
import { SkeletonTopLoading } from '../../widgets/SkeltonEffect';

function CompanyAnalytics() {
  const [cardData, setCardData] = useState([]);
  const [is_loading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const user_response = await getOneCompanyDetails();

        if (user_response?.data?.data) {
          const info = user_response.data.data.user;
          const count_info = user_response.data.data.count_info;
          setCardData([
            { title: 'Company Name', data: localStorage.getItem('company_name') },
            { title: 'Address', data: info.city ? info.city : '--' },
            { title: 'Contact Person', data: info.name ? info.name : '--' },
            { title: 'Contact Role', data: info.role === "super_admin" ? "Super Admin" : "--" },
            { title: 'Contact', data: info.phone_no ? info.phone_no : '--' },
            { title: 'Production Crate Count', data: count_info.production_total_crate ? count_info.production_total_crate : '0' },
            { title: 'Dispatch Crate Count', data: count_info.dispatch_total_crate ? count_info.dispatch_total_crate : '0' },
            { title: 'Empty Crate Count', data: count_info.reception_total_crate ? count_info.reception_total_crate : '0' },
            { title: 'Retailer Crate Count', data: count_info.retailer_total_crate ? count_info.retailer_total_crate : '0' },
            { title: 'Total Difference Crate Count', data: count_info.missing_notification_data ? count_info.missing_notification_data : '0' },
          ])
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <Box className='m-a-30'>
      {is_loading ?
        <SkeletonTopLoading />
        : <Box
          sx={{
            backgroundColor: '#6ca0dc',
            padding: 2,
            borderRadius: 2,
            width: '100%',
          }}
        >
          <Grid container spacing={2}>
            {cardData.map((item, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={2.4}
                key={index}
                sx={{
                  borderBottom: index < 5 ? '1px solid white' : 'none',
                  padding: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Box
                  sx={{
                    textAlign: 'left',
                    borderRight: (index + 1) % 5 !== 0 ? '1px solid white' : 'none',
                    width: '100%',
                  }}
                >
                  <Typography variant="h6" className='white' >
                    {item.title}
                  </Typography>
                  <Typography variant="h5" className='fw-bold white'>
                    {item.data}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        }
    </Box>
  );
}

export default CompanyAnalytics;
