import React from 'react'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function CustomDropdown({ data_list, title, item_name_key, selected_value, set_selected_result, disabled, required }) {
    const [selected_item, set_selected_item] = React.useState('')

    React.useEffect(() => {
        set_selected_item(selected_value)
    }, [selected_value])

    const handleChange = (event) => {
        set_selected_item(event.target.value)
        set_selected_result(event.target.value)
    };

    return (
        <Box>
            <FormControl sx={{ width: 230 }} size="small">
                <InputLabel id="demo-simple-select-label">{title}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selected_item}
                    disabled={disabled}
                    required={required}
                    label={title}
                    onChange={handleChange}
                >
                    {data_list && data_list.map((item, index) => (
                        <MenuItem key={index} value={item[`${item_name_key}`]}>{item[`${item_name_key}`]}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    )
}

export default CustomDropdown