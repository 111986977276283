import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DropDown from '../../widgets/DropDown';
import { RiArrowDownDoubleFill } from "react-icons/ri";
import { MdOutlineKeyboardDoubleArrowUp } from "react-icons/md";
import ReportResult from './ReportResult';
import DatePicker from 'react-multi-date-picker';
import { getProductLineWeightData, getReportByProcessName, getShiftData, getVehicleData } from '../../utils/api.reports';
import CustomDropdown from '../../widgets/CustomDropdown';
import { tags, devices } from './ConstantReportOptions';
import moment from 'moment';
import { useCompanyContext } from '../../common/companyContext';

function Report() {
    const location = useLocation();
    const report_name = location.state.report_name;
    const { updateSelectedCompany } = useCompanyContext();
    const [is_more_filter, setIsMoreFilter] = useState(false);
    const [shifts, setShift] = useState([]);
    const [selected_shift, set_selected_shift] = useState('');
    const [vehicle_list, setVehicleList] = useState([]);
    const [selected_vehicle, set_selected_vehicle] = useState('');
    const [reports, setReport] = useState([]);
    const [selected_report, set_selected_report] = useState('');
    const [selected_tag, set_selected_tag] = useState([]);
    const [selected_device, set_selected_device] = useState([]);
    const [lines, setLines] = useState([]);
    const [selected_line, set_selected_line] = useState([]);
    const [weight_types, set_weight_types] = useState([]);
    const [selected_weight_type, set_selected_weight_type] = useState('');
    const [products, setProducts] = useState([]);
    const [selected_product, set_selected_product] = useState('');
    const [start_date, set_start_date] = useState('')
    const [end_date, set_end_date] = useState('')
    const [is_show_result_table, setIsShowResultTable] = useState(false);
    const [show_selected_date, setShowSelectedDate] = useState([]);

    useEffect(() => {
        claerFilter();
        (async () => {
            const report_option_list = await getReportByProcessName(report_name);
            report_option_list?.data?.data && setReport(report_option_list?.data?.data);
            const shift_response = await getShiftData();
            setShift(shift_response?.data?.data?.shift_data);

            if (report_name === 'Dispatch Dock') {
                const vehicle_response = await getVehicleData();
                setVehicleList(vehicle_response?.data?.data);
            } else if (report_name === 'Production') {
                const prodcuction_response = await getProductLineWeightData()
                if (prodcuction_response?.data?.data) {
                    setLines(prodcuction_response?.data?.data?.line_data);
                    modifiedWeightData(prodcuction_response?.data?.data?.weight_status_data);
                    setProducts(prodcuction_response?.data?.data?.product_data);
                }
            }
        })()
    }, [report_name, updateSelectedCompany]);

    useEffect(() => {
        setIsShowResultTable(false);
    }, [report_name, selected_shift, selected_vehicle, selected_report, selected_tag, selected_device, selected_line, selected_weight_type, selected_product, start_date, end_date]);

    const onHandleInputDate = (dateRange) => {
        if (dateRange && dateRange.length >= 2) {
            const startDateFormate = dateRange[0]?.format() ? dateRange[0]?.format() : ''
            const endDateFormate = dateRange[1]?.format() ? dateRange[1]?.format() : ''
            set_start_date(moment(startDateFormate, 'DD/MM/YYYY').format('YYYY-MM-DD') + 'T00:00:00.000Z')
            set_end_date(moment(endDateFormate, 'DD/MM/YYYY').format('YYYY-MM-DD') + 'T23:59:59.000Z')
            setShowSelectedDate([startDateFormate, endDateFormate])
        }
    }

    const modifiedWeightData = (data) => {
        let weight_type = [];
        data && data.map((key) => {
            return weight_type.push({ 'weight_type': key });
        });
        set_weight_types(weight_type);
    }

    const claerFilter = () => {
        set_selected_shift('');
        set_selected_vehicle('');
        set_selected_report('');
        set_selected_tag([]);
        set_selected_device([]);
        set_selected_line([]);
        set_selected_weight_type('');
        set_selected_product('');

        setVehicleList([]);
        setLines([]);
        setProducts([]);
        set_weight_types([]);

        setIsShowResultTable(false);
    }

    return (
        <Box className='m-l-20 m-r-20 p-20'>
            <form onSubmit={(event) => {
                event.preventDefault();
                setIsShowResultTable(true)
            }}>
                <Box>
                    <Box className='d-flex justify-between'>
                        <p className='fs-20'><span>Report Name: &nbsp;</span><span className='dark-blue fw-bold underline APP-COLOR'>{report_name}</span></p>
                        <div>
                            <DatePicker
                                range
                                required
                                rangeHover
                                placeholder=" Start Date ~ End Date "
                                format="DD/MM/YYYY"
                                onChange={onHandleInputDate}
                                value={show_selected_date}
                                style={{ marginRight: '10px', padding: '18px 27px', height: '35px', background: 'white' }}
                            // maxDate={new Date().setDate(new Date().getDate() + 1)}
                            />
                        </div>
                    </Box>

                    {/* Filters Dropdown */}
                    <Box className="border b-r-10 m-t-5 bg-white">
                        <div className='d-flex justify-between m-t-10'>
                            <h5 className='p-l-20 fs-16'>Filters</h5>
                            <div className='cursor' onClick={() => setIsMoreFilter(!is_more_filter)}>
                                <p className='d-flex align-items-center fw-bold p-r-20 APP-COLOR'>
                                    <span style={{ marginTop: '3px' }} className='m-l-5'>
                                        {is_more_filter ? <MdOutlineKeyboardDoubleArrowUp size={18} /> : <RiArrowDownDoubleFill size={18} />}
                                    </span>
                                    <span>{is_more_filter ? 'Collapse' : 'Expand'}</span></p>
                            </div>
                        </div>
                        <div className='d-flex justify-between p-l-16 p-b-16 p-r-16 g-20'>
                            <CustomDropdown
                                required
                                title={'Report Type*'}
                                data_list={reports}
                                item_name_key={'report_name'}
                                selected_value={selected_report}
                                set_selected_result={set_selected_report}
                            />
                            <CustomDropdown
                                required
                                title={'Tag*'}
                                data_list={tags}
                                item_name_key={'tag_type'}
                                selected_value={selected_tag}
                                set_selected_result={set_selected_tag}
                            />
                            <CustomDropdown
                                required={report_name !== 'Dispatch' ? false : true}
                                title={'Device*'}
                                disabled={report_name !== 'Dispatch'}
                                data_list={devices}
                                item_name_key={'device_type'}
                                selected_value={selected_device}
                                set_selected_result={set_selected_device}
                            />
                            <DropDown
                                required={false}
                                title={'Line'}
                                list={lines}
                                disabled={report_name === 'Dispatch'}
                                item_name_key={'line'}
                                selected_value={selected_line}
                                set_selected_result={set_selected_line}
                            />
                            <CustomDropdown
                                required={false}
                                title={'Shift'}
                                data_list={shifts}
                                item_name_key={'shift_name'}
                                selected_value={selected_shift}
                                set_selected_result={set_selected_shift}
                            />

                            {!is_more_filter && <Box sx={{ width: 200 }}>
                                <Button variant='contained'
                                    className='Header-Background'
                                    sx={{ width: 200, padding: '8px 20px' }}
                                    type='submit'>
                                    Show
                                </Button>
                            </Box>}
                        </div>
                        <Box>
                            {is_more_filter && <Box className='d-flex justify-start p-l-16 p-b-16 p-r-16' style={{ gap: '68px' }}>
                                <DropDown
                                    required={false}
                                    title={'Product'}
                                    list={products}
                                    item_name_key={'product'}
                                    selected_value={selected_product}
                                    set_selected_result={set_selected_product}
                                />
                                <CustomDropdown
                                    required={false}
                                    title={'Weight'}
                                    data_list={weight_types}
                                    item_name_key={'weight_type'}
                                    selected_value={selected_weight_type}
                                    set_selected_result={set_selected_weight_type}
                                />
                                <CustomDropdown
                                    required={false}
                                    title={'Vehicle No'}
                                    data_list={vehicle_list}
                                    item_name_key={'vehicle_no'}
                                    selected_value={selected_vehicle}
                                    set_selected_result={set_selected_vehicle}
                                />
                                <CustomDropdown
                                    required={false}
                                    title={'Factory Name'}
                                    disabled={true}
                                    // list={shifts}
                                    item_name_key={'shift_name'}
                                // selected_value={selected_shift}
                                // set_selected_result={set_selected_shift}
                                />
                                <Box sx={{ width: 200 }}>
                                    {is_more_filter && <Button
                                        className='Header-Background'
                                        variant='contained'
                                        sx={{ width: 230, padding: '8px 20px' }}
                                        type='submit'
                                    >
                                        Show
                                    </Button>}
                                </Box>
                            </Box>}
                        </Box>
                    </Box>
                </Box >
            </form>

            {/* Result Table */}
            {is_show_result_table && <ReportResult
                process_name={report_name}
                report_type={selected_report}
                filter_data={
                    {
                        starting_date: start_date,
                        ending_date: end_date,
                        shift: selected_shift,
                        crate_id: selected_tag === 'With' ? true : false,
                        line: selected_line.id,
                        device: selected_device === 'Android Application' ? 'mobile' : 'machine',
                        vehicle: selected_vehicle,
                        weight_type: selected_weight_type,
                        product: selected_product,
                        company_name: localStorage.getItem('company_name')
                    }
                }
            />}

        </Box>
    );
}

export default Report;