import React, { createContext, useContext, useState } from 'react';
const CompanyContext = createContext();
export const useCompanyContext = () => useContext(CompanyContext);

export const CompanyProvider = ({ children }) => {
  const [selectedCompany, setSelectedCompany] = useState(0);

  const updateSelectedCompany = (company) => {
    setSelectedCompany(company);
  };

  return (
    <CompanyContext.Provider value={{ selectedCompany, updateSelectedCompany }}>
      {children}
    </CompanyContext.Provider>
  );
}