import api from './interceptor'

export const getRetailers = async () => {
    try {
        const response = await api.get(`input/retailer/get_retailers`, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const addNewRetailerData = async (retailer_data) => {
    try {
        const response = await api.post(`input/retailer/add_retailer`,
            retailer_data,
            { params: { company_name: localStorage.getItem('company_name') }, }
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const updateRetailerData = async (retailer_id, retailer_data) => {
    try {
        console.log(retailer_data, retailer_id);
        const response = await api.post(`input/retailer/update_retailer`, retailer_data, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteRetailer = async (retailer_id) => {
    try {
        const response = await api.post(`input/retailer/delete_retailer`, {retailer_id: retailer_id}, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

