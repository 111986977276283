import api from './interceptor'

export const getShiftData = async () => {
    try {
        const response = await api.get(`input/report/get_shift_Filter`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getVehicleData = async () => {
    try {
        const response = await api.get(`input/report/get_vehicle_data`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getProductLineWeightData = async () => {
    try {
        const response = await api.get(`input/report/get_product_and_line_filter`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getReceptionLogData = async (filter_data) => {
    try {
        const response = await api.get(`input/report/get_report/reception_log`, { params: filter_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getReceptionSummaryData = async (filter_data) => {
    try {
        const response = await api.get(`input/report/get_report/reception_summary`, { params: filter_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getProductLogData = async (filter_data) => {
    try {
        const response = await api.get(`input/report/get_report/production_log`, { params: filter_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getProductSummaryData = async (filter_data) => {
    try {
        const response = await api.get(`input/report/get_report/production_summary`, { params: filter_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getDispacthLogData = async (filter_data) => {
    try {
        const response = await api.get(`input/report/get_report/dispatch_log`, { params: filter_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getDispacthSummaryData = async (filter_data) => {
    try {
        const response = await api.get(`input/report/get_report/dispatch_summary`, { params: filter_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getScrapLogData = async (filter_data) => {
    try {
        const response = await api.get(`input/report/get_report/getScrapCrateData`, { params: filter_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getNewLogData = async (filter_data) => {
    try {
        const response = await api.get(`input/report/get_report/getNewCrateData`, { params: filter_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getNewLeakyData = async (filter_data) => {
    try {
        const response = await api.get(`input/report/get_report/getLeakyCrateData`, { params: filter_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getAllCratesData = async (filter_data) => {
    try {
        const response = await api.get(`input/report/get_report/getAllCrateWithStatus`, { params: filter_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getRetailerLogData = async (filter_data) => {
    try {
        const response = await api.get(`input/report/get_report/retailer_log`, { params: filter_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getRetailerSummaryData = async (filter_data) => {
    try {
        const response = await api.get(`input/report/get_report/retailer_summary`, { params: filter_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getDownloadCSV = async (filter_data) => {
    try {
        const response = await api.get(`input/report/get_report/download_csv`, { params: filter_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getCrateSearchDetails = async (filter_data) => {
    try {
        const response = await api.get(`input/report/get_report/crate_search_engine`, { params: filter_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getRejecetedProjectionLogData = async (filter_data) => {
    try {
        const response = await api.get(`input/report/get_report/getRejectedCrateLogOnProduction`, { params: filter_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getRejecetedProjectionSummaryData = async (filter_data) => {
    try {
        const response = await api.get(`input/report/get_report/getRejectedCrateSummaryOnProduction`, { params: filter_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getReportByProcessName = async (process_name) => {
    try {
        const response = await api.get(`input/report/get_report_by_process`, {
            params: { process_name: process_name }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getMissingCrateData = async (filter_data) => {
    filter_data.company_name = localStorage.getItem('company_name')
    try {
        const response = await api.get(`input/notification/missing_crates`, { params: filter_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getDuplicateCrateData = async (filter_data) => {
    filter_data.company_name = localStorage.getItem('company_name')
    try {
        const response = await api.get(`input/notification/duplicate_crates`, { params: filter_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getUnidentifiedCrateData = async (filter_data) => {
    filter_data.company_name = localStorage.getItem('company_name')
    try {
        const response = await api.get(`input/notification/unidentified_crates`, { params: filter_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getTotalNotificationCount = async () => {
    try {
        const response = await api.get(`input/notification/total_notification_count`, { params: { company_name: localStorage.getItem('company_name') } });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getImageUrl = async (image_key, is_verified_new_crate_tag) => {
    const parameters = {
        image_key: image_key
    }
    if (is_verified_new_crate_tag === true) {
        parameters.is_verified_new_crate_tag = is_verified_new_crate_tag
    }
    try {
        const response = await api.get('input/report/get_report/getImageUrl', {
            params: parameters
        });
        if (response.data) {
            window.open(response.data.data, '_blank');
        }
    } catch (error) {
        alert(error);
    }
}
