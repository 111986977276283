import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function RecentAlertTable() {

    const data = [
        {
            id: 1,
            name: 'Missing',
            vehicle: 'GJ77KKU',
            time: '12:00 AM',
        },
        {
            id: 2,
            name: 'Duplicate',
            vehicle: 'GJ77KKU',
            time: '12:00 AM',
        },
        {
            id: 3,
            name: 'Unidentified',
            vehicle: 'GJ77KKU',
            time: '12:00 AM',
        },
    ];

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell component="th" className='fw-bold' scope="row" align="left">
                                {row.name}
                            </TableCell>
                            <TableCell align="left">
                                {row.vehicle}
                            </TableCell>
                            <TableCell align="left">
                                {row.time}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


export default RecentAlertTable