import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { MenuItem, Select, FormControl, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCompanyContext } from '../../common/companyContext';

export default function CompanyStatusTable({ data }) {
  const navigate = useNavigate();
  const { updateSelectedCompany } = useCompanyContext();
  const [companyData, setCompanyData] = useState([]);
  const [filtered_data, setFilterData] = useState([]);
  const [status_filter, setStatusFilter] = useState('true');

  useEffect(() => {
    setCompanyData(data);
  }, [data]);

  useEffect(() => {
    const filteredData = companyData.filter((row) => {
      if (status_filter === '') {
        return true;
      }
      const isActive = status_filter === 'true' ? true : false;
      return row.active === isActive;
    })
    setFilterData(filteredData);
  }, [companyData, status_filter]);

  const tableHeaders = [
    'Name of dairy plant',
    'Name of module',
    'Status',
    'Alert',
    'Last Session Date',
    'Last Session start time',
  ];

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const onHandleShowCompanyAnalytics = (company) => {
    navigate('/dashboard/analytics', { state: { company } });
    localStorage.setItem('company_name', company);
    updateSelectedCompany(company);
  }

  const onGetModuleName = (row) => {
    const excludedKeys = ['active', 'company'];
    return Object.keys(row).filter(key => row[key] && !excludedKeys.includes(key)).join(', ');
  }  

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <TableCell key={header} align="left" className="APP-FONT border fw-bold">
                  {header === 'Status' ? (
                    <div className="d-flex justify-between">
                      <p className='m-t-10'>{header}</p>
                      <FormControl size="small">
                        <Select
                          value={status_filter === '' ? 'All' : status_filter}
                          onChange={handleStatusFilterChange}
                          inputProps={{
                            sx: { height: '32px' }
                          }}
                        >
                          <MenuItem value="">
                            <em>All</em>
                          </MenuItem>
                          <MenuItem value="true">Active</MenuItem>
                          <MenuItem value="false">Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  ) : (
                    header
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered_data.map((row, index) => (
              <TableRow key={index}>
                <TableCell className="border APP-COLOR-2 underline cursor" component="th" scope="row" align="left"
                  onClick={() => onHandleShowCompanyAnalytics(row.company.company_name)}>
                  {row.company.company_name}
                </TableCell>
                <TableCell className="border" align="left">
                  {onGetModuleName(row)}
                </TableCell>
                <TableCell className="border" align="left">
                  {row.active ? 'Active' : 'Inactive'}
                </TableCell>
                <TableCell className="border" align="left">
                  {row.alert}
                </TableCell>
                <TableCell className="border" align="left">
                  {row.last_session_date}
                </TableCell>
                <TableCell className="border" align="left">
                  {row.last_session_start_time}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br/>
    </Box>
  );
}