import moment from 'moment-timezone';

export const checkValidPhoneNumber = (phoneNo) => {
    const pattern = /^(\+91)[6789]{1}\d{9}$/;
    return pattern.test(phoneNo)
}

export const showDateAndTime = (date_time) => {
    const istMoment = moment.utc(date_time, 'YYYY-MM-DD HH:mm:ss');
    // const istMoment = utcMoment.tz('Asia/Kolkata');
    // const istDateTimeFormatted = istMoment.format('DD MMM YYYY, hh:mm A')
    return { date: istMoment.format('MMMM DD, YYYY'), time: istMoment.format('hh:mm A') };
}