import React, { useState, useEffect, useCallback } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, TablePagination } from '@mui/material';
import { FaCloudArrowDown } from "react-icons/fa6";
import { useCompanyContext } from '../../common/companyContext';
import { getAllCratesData, getDispacthLogData, getDispacthSummaryData, getDownloadCSV, getNewLeakyData, getNewLogData, getProductLogData, getProductSummaryData, getReceptionLogData, getReceptionSummaryData, getRetailerLogData, getRetailerSummaryData, getScrapLogData } from '../../utils/api.reports';
import { AllCrateIdsHeaders, AllCrateIdsTableRows, DispatchAndroidLogHeaders, DispatchAndroidLogTableRows, DispatchAndroidSummaryHeaders, DispatchAndroidSummaryTableRows, DispatchIndustrialCameraLogHeaders, DispatchIndustrialCameraLogTableRows, DispatchIndustrialCameraSummaryHeaders, DispatchIndustrialCameraSummaryTableRows, LeakyLogHeaders, LeakyLogTableRows, NewCrateLogHeaders, NewCrateLogTableRows, ReceptionLogHeaders, ReceptionLogTableRows, ReceptionSummaryHeaders, ReceptionSummaryTableRows, RejectedCrateLogHeaders, RejectedCrateLogTableRows, RetailerLogHeaders, RetailerLogTableRows, RetailerSummaryHeaders, RetailerSummaryTableRows, ScrapLogHeaders, ScrapLogTableRows, productionLogHeaders, productionLogTableRows, productionSummaryHeaders, productionSummaryTableRows } from '../../TableSetting';
import RotatingLoading from '../../widgets/RotatingLoading';

function ReportResult({ process_name, report_type, filter_data }) {
  const { updateSelectedCompany } = useCompanyContext();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [is_loading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      filter_data.sort_attribute = 'date';
      filter_data.sort_direction = 'desc';
      filter_data.page_index = +page;
      filter_data.page_size = +rowsPerPage;

      switch (process_name) {
        case 'Empty Crate':
          if (report_type === 'Crate Reception Log') {
            const response = await getReceptionLogData(filter_data);
            setTableHeader(ReceptionLogHeaders);
            setTotalRows(response.data.data.total_length);
            setTableRows(ReceptionLogTableRows(response.data.data.data));
          } else if (report_type === 'Crate Reception Summary') {
            const response = await getReceptionSummaryData(filter_data);
            setTableHeader(ReceptionSummaryHeaders);
            setTotalRows(response.data.data.total_length);
            setTableRows(ReceptionSummaryTableRows(response.data.data.data));
          }
          break;
        case 'Production':
          if (report_type === 'Production Log') {
            setTableHeader(productionLogHeaders);
            const response = await getProductLogData(filter_data);
            setTotalRows(response.data.data.total_length);
            setTableRows(productionLogTableRows(response.data.data.data));
          } else if (report_type === 'Total Production Summary') {
            setTableHeader(productionSummaryHeaders);
            const response = await getProductSummaryData(filter_data);
            setTotalRows(response.data.data.total_length);
            setTableRows(productionSummaryTableRows(response.data.data.data));
          }
          break;
        case 'Dispatch Dock':
          if (report_type === 'Dispatch Log') {
            setTableHeader(filter_data.device === 'mobile' ? DispatchAndroidLogHeaders : DispatchIndustrialCameraLogHeaders);
            const response = await getDispacthLogData(filter_data);
            setTotalRows(response.data.data.total_length);
            setTableRows(filter_data.device === 'mobile' ? DispatchAndroidLogTableRows(response.data.data.data) : DispatchIndustrialCameraLogTableRows(response.data.data.data));
          } else if (report_type === 'Dispatch Summary') {
            setTableHeader(filter_data.device === 'mobile' ? DispatchAndroidSummaryHeaders : DispatchIndustrialCameraSummaryHeaders);
            const response = await getDispacthSummaryData(filter_data);
            setTotalRows(response.data.data.total_length);
            setTableRows(filter_data.device === 'mobile' ? DispatchAndroidSummaryTableRows(response.data.data.data) : DispatchIndustrialCameraSummaryTableRows(response.data.data.data));
          }
          break;
        case 'At Retailer':
          if (report_type === 'Retailer log') {
            setTableHeader(RetailerLogHeaders);
            const response = await getRetailerLogData(filter_data);
            setTotalRows(response.data.data.total_length);
            setTableRows(RetailerLogTableRows(response.data.data.data));
          } else if (report_type === 'Retailer Summary') {
            setTableHeader(RetailerSummaryHeaders);
            const response = await getRetailerSummaryData(filter_data);
            setTotalRows(response.data.data.total_length);
            setTableRows(RetailerSummaryTableRows(response.data.data.data));
          }
          break;
        case 'Scrap Crate': {
          const response = await getScrapLogData(filter_data);
          setTableHeader(ScrapLogHeaders);
          setTotalRows(response.data.data.total_length);
          setTableRows(ScrapLogTableRows(response.data.data.data));
          break;
        }
        case "Leaky Crate": {
          const response = await getNewLeakyData(filter_data);
          setTableHeader(LeakyLogHeaders);
          setTotalRows(response.data.data.total_length);
          setTableRows(LeakyLogTableRows(response.data.data.data));
          break;
        }
        case "New Crate": {
          const response = await getNewLogData(filter_data);
          setTableHeader(NewCrateLogHeaders);
          setTotalRows(response.data.data.total_length);
          setTableRows(NewCrateLogTableRows(response.data.data.data));
          break;
        }
        case 'All Crate Ids': {
          const response = await getAllCratesData(filter_data);
          setTableHeader(AllCrateIdsHeaders);
          setTotalRows(response.data.data.total_length);
          setTableRows(AllCrateIdsTableRows(response.data.data.data));
          break;
        }
        case 'Rejected Crates': {
          if (report_type === 'Production Crates Log') {
            setTableHeader(RejectedCrateLogHeaders);
            const response = await getRetailerLogData(filter_data);
            setTotalRows(response.data.data.total_length);
            setTableRows(RejectedCrateLogTableRows(response.data.data.data));
          } else if (report_type === 'Production Crates Summary') {
            setTableHeader(productionSummaryHeaders);
            const response = await getProductSummaryData(filter_data);
            setTotalRows(response.data.data.total_length);
            setTableRows(productionSummaryTableRows(response.data.data.data));
          }
          break;
        }
        default:
          break;
      }
    } catch (error) {
      console.error('Error fetching retailers:', error);
    } finally {
      setIsLoading(false);
    }
  }, [page, rowsPerPage, process_name, filter_data]);

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, updateSelectedCompany, fetchData]);

  const handlePageChange = (event, newPage) => {
    if (newPage >= 0) {
      setPage(newPage + 1);
    }
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onDownloadCSV = async () => {
    try {
      filter_data.log_summary_type = getLogSummaryType();
      console.log(filter_data);
      const response = await getDownloadCSV(filter_data);
      if (response?.data) {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = report_type.toLowerCase();
        a.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getLogSummaryType = () => {
    switch (report_type) {
      case 'Reception Log':
        return 'reception_log';
      case 'Reception Summary':
        return 'reception_summary';
      case 'Production Log':
        return 'production_log';
      case 'Production Summary':
        return 'production_summary';
      case 'Dispatch Log':
        return 'dispatch_log';
      case 'Retailer Summary':
        return 'dispatch_summary';
      case 'Scrap crates log':
        return 'scrap_crate_log';
      case 'Leaky crates log':
        return 'new_leaky_log';
      case 'New crates log':
        return 'new_crates_log';
      case 'Crates Status log':
        return 'all_crate_ids';
      case 'Production Crates Log':
        return 'rejected_production_crate_log';
      case 'Production Crates Summary':
        return 'rejected_production_crate_summary';
      default:
        return '';
    }
  }

  return (
    <Box className='m-t-20'>
      <Box className='d-flex justify-between'>
        <h3>Table</h3>
        <Box className='d-flex g-5'>
          <Button style={{ color: 'black' }}
            variant='outlined'
            className='m-b-5'
            onClick={onDownloadCSV}
            startIcon={<FaCloudArrowDown style={{ color: 'darkblue' }} />}
          >Download Report In CSV
          </Button>
        </Box>
      </Box>
      <Box className='m-t-5'>
        {is_loading
          ? <Box><RotatingLoading /></Box>
          : <Box>
            <TableContainer component={Paper} style={{ maxHeight: '70%', overflowY: 'auto' }}>
              <Table stickyHeader sx={{ minWidth: 650, maxHeight: 10, overflowY: 'auto' }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    {tableHeader.map((header) => (
                      <TableCell key={header} align="left" className='cursor border fw-bold APP-COLOR-2'>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableRows && tableRows.length
                    ? tableRows
                    : <TableRow>
                      <TableCell className='p-10' colSpan={tableHeader.length} align="center">No Records Found</TableCell>
                    </TableRow>}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 25, 50, 100]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>}
      </Box>
    </Box>
  );
}

export default ReportResult;