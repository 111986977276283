import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Container, Grid, TablePagination, Typography, TextField, Button, Box, Modal, IconButton } from '@mui/material';
import { MdAdd } from "react-icons/md";
import { errorMessage, successMessage } from '../../common/Toast';
import { AiOutlineDelete } from "react-icons/ai";
import AppConformationModel from '../../widgets/AppConformationModel';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import { addShift, deletShift, getShift } from '../../utils/api.user';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    border: '2px solid aliceblue',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

const ManageShift = () => {
    const headers = ['Sr.No', "Shift Name", "Lower Limit", 'Upper Limit', 'Delete'];
    const [shift_name, setShiftName] = useState('');
    const [shift_data, setShiftData] = useState([]);
    const [upper_time_limit, setUpperTimeLimit] = useState('');
    const [lower_time_limit, setLowerTimeLimit] = useState('');
    const [is_open_add_shift, setIsOpenAddShift] = useState(false);
    const [is_delete_conformation, setIsDeleteConformation] = useState(false)
    const [shift_id, setShiftId] = useState('');
    const [title, setTitle] = useState('')
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [message, setMessage] = useState('')

    useEffect(() => {
        (async () => {
            onGetAllShiftsData();
        })()
    }, [])

    const onGetAllShiftsData = async () => {
        try {
            const response = await getShift();
            setShiftData(response.data.data);
        } catch (error) {
            console.error(error);
        }
    }

    const addNewShift = async (event) => {
        event.preventDefault();
        try {
            const shift_data = {
                shift_name: shift_name,
                lower_time_limit: lower_time_limit,
                upper_time_limit: upper_time_limit
            };
            const response = await addShift(shift_data);
            console.log(response);
            if (response.status === 200) {
                successMessage(response.data.message);
                handleClosePopUp();
                onGetAllShiftsData();
            }
            setShiftName('');
            setLowerTimeLimit('');
            setUpperTimeLimit('');
        } catch (error) {
            errorMessage(error.response.data.message);
        }
    };

    const onHandleDeleteConformation = (row_data) => {
        setShiftId(row_data.id);
        setTitle(`Delete Shift (${row_data.shift_name})`)
        setMessage(`Do you want delete this Shift ?`)
        setIsDeleteConformation(true);
    }

    const onDeleteShift = async () => {
        try {
            const delete_response = await deletShift(shift_id)
            if (delete_response?.status === 200) {
                successMessage(delete_response?.data?.message)
                onGetAllShiftsData()
            }
        } catch (error) {
            errorMessage(error?.response?.data?.message)
        } finally {
            setShiftId('')
        }
    }

    const handleClosePopUp = () => {
        setIsOpenAddShift(false);
    }

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Container>
            <AppConformationModel
                open={is_delete_conformation}
                setOpen={setIsDeleteConformation}
                title={title}
                message={message}
                onConformAction={onDeleteShift}
            />

            <div style={{ marginTop: '2rem' }}>
                <Box className="d-flex justify-between">
                    <p><b>Shift</b></p>
                    <Button onClick={() => setIsOpenAddShift(true)} startIcon={<MdAdd />}>Add New Shift</Button>
                </Box>
                <Box className='m-t-10'>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650, borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    {headers.map((header) => (
                                        <TableCell className="border fw-bold" key={header} align="left">{header}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {shift_data && shift_data.map((row, index) => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row" align='left'>{(page * rowsPerPage) + index + 1}</TableCell>
                                        <TableCell className="border" align='left'>{row.shift_name}</TableCell>
                                        <TableCell className="border" align="left">{row.lower_limit}</TableCell>
                                        <TableCell className="border" align="left">{row.upper_limit}</TableCell>
                                        <TableCell className="border cursor" align="left"><AiOutlineDelete size={25} onClick={() => onHandleDeleteConformation(row)} /></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={shift_data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                </Box>
            </div>

            <Modal
                open={is_open_add_shift}
                onClose={handleClosePopUp}
            >
                <Box sx={style}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <IconButton
                                aria-label="close"
                                onClick={handleClosePopUp}
                                sx={{
                                    position: 'absolute',
                                    top: 8,
                                    right: 8,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6"><b>Add Shift</b></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <form onSubmit={addNewShift}>
                                <label><b>Shift Name</b></label>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="shift_name"
                                    name="shift_name"
                                    className='m-t-5'
                                    value={shift_name}
                                    onChange={(e) => setShiftName(e.target.value)}
                                    placeholder="Shift Name"
                                    required
                                />
                                <br></br>
                                <label className='m-t-20'><b>Upper Time Limit</b></label>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="lower_time_limit"
                                    name="lower_time_limit"
                                    className='m-t-5'
                                    value={lower_time_limit}
                                    type='time'
                                    onChange={(e) => setLowerTimeLimit(e.target.value)}
                                    placeholder="Lower Limit"
                                    required
                                />
                                <br></br>
                                 <label className='m-t-20'><b>Lower Time Limit</b></label>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="upper_time_limit"
                                    name="upper_time_limit"
                                    className='m-t-5'
                                    value={upper_time_limit}
                                    type='time'
                                    onChange={(e) => setUpperTimeLimit(e.target.value)}
                                    placeholder="Lower Limit"
                                    required
                                />
                                <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                                    <Button type="submit" variant="contained" size="large">Add Shift</Button>
                                </div>
                            </form>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Container>
    );
};

export default ManageShift