import api from './interceptor'

export const getUsers = async () => {
    try {
        const response = await api.get(`input/user/get_users`, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const addNewuserData = async (user_data) => {
    try {
        const response = await api.post(`input/user/add_user`,
            user_data,
            { params: { company_name: localStorage.getItem('company_name') }, }
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const updateUserData = async (user_data) => {
    try {
        const response = await api.post(`input/user/update_user`, user_data, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteuser = async (user_id) => {
    try {
        const response = await api.post(`input/user/delete_user`, {user_id: user_id}, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getLines = async () => {
    try {
        const response = await api.get(`input/user/get_lines`, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const addLine = async (line_data) => {
    try {
        const response = await api.post(`input/user/add_line`, line_data, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const deletLine = async (line_id) => {
    try {
        const response = await api.post(`input/user/delete_line`, {line_id: line_id}, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getShift = async () => {
    try {
        const response = await api.get(`input/user/get_shifts`, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const addShift = async (shift_data) => {
    try {
        const response = await api.post(`input/user/add_Shift`, shift_data, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const deletShift = async (shift_id) => {
    try {
        const response = await api.post(`input/user/delete_shift`, {shift_id: shift_id}, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

