import React from 'react'
import { RotatingLines } from 'react-loader-spinner'

function RotatingLoading() {
    return (
        <div style={{zIndex: 100}} className='d-flex justify-center h-60vh w-100'>
            <RotatingLines
                visible={true}
                height="80"
                width="80"
                strokeColor="rgb(2, 2, 77)"
                strokeWidth="4"
                animationDuration="5"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    )
}

export default RotatingLoading