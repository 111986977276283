import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const DropDown = ({ title, list, item_name_key, selected_value, set_selected_result, disabled, required }) => {
    const [selected_item, set_selected_item] = React.useState('')

    React.useEffect(() => {
        if(selected_value){
            set_selected_item(selected_value.id)
        }
    }, [selected_value])

    const handleChange = (event) => {
        set_selected_item(event.target.value)
        const selectedObject = list.find(item => item.id === event.target.value);
        set_selected_result(selectedObject);
    };

    return (
        <FormControl sx={{ width: 230 }} size="small">
            <InputLabel id="demo-select-small-label">{title}</InputLabel>
            <Select
                value={selected_item}
                label={title}
                disabled={disabled}
                required={required}
                onChange={(e) => handleChange(e)}
            >
                <MenuItem value={''}><em>select</em></MenuItem>
                {list && list.map((item, index) => (
                    <MenuItem key={index} value={item.id}>{item[`${item_name_key}`]}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default DropDown