import React from 'react';
import { Box, Grid, Paper, Skeleton } from '@mui/material';

const SkeletonTopLoading = () => {
  const count = 5;
  return (
    <Box>
      <Paper sx={{ marginTop: 5 }}>
        <Grid container spacing={2}>
          {Array.from({ length: count }).map((_, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={2.4}
              key={index}
              sx={{
                borderBottom: index < 5 ? '1px solid white' : 'none',
                padding: 3,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  textAlign: 'left',
                  borderRight: (index + 1) % 5 !== 0 ? '1px solid white' : 'none',
                  padding: (index) % 5 === 0 ? '0 20px' : 'none',
                  width: '100%',
                }}
              >
                <Skeleton animation="wave" variant="text" width={250} height={40} />
                <Skeleton className='m-t-5' animation="wave" variant="text" width={250} height={20} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
};

const SkeletonBodyLoading = () => {
  return (
    <Box className='d-flex justify-between m-t-10'>
      <SkeletonWithPieChart />
      <SkeletonWithBarChart />
      <SkeletonWithTable />
    </Box>
  );
};

const SkeletonWithPieChart = () => {
  return (
    <Box sx={{ width: '33%' }}>
      <Paper sx={{ marginTop: 2 }}>
        <Box>
          <Box sx={{ padding: '5px 20px' }}><Skeleton animation="wave" width={250} height={30} /></Box>
          <Skeleton animation="wave" variant="circular" width='100%' height={2} />
          <Skeleton className='m-t-5 m-l-20' animation="wave" width={100} height={20} />
          <Box className='d-flex justify-between p-20 g-20' sx={{ height: '220px' }}>
            <Skeleton className='m-t-10' animation="wave" variant="circular" width={150} height={150} />
            <Box>
              <Skeleton className='m-t-15' animation="wave" width={250} height={30} />
              <Skeleton animation="wave" width={250} height={30} />
              <Skeleton animation="wave" width={250} height={30} />
              <Skeleton animation="wave" width={250} height={30} />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

const SkeletonWithBarChart = () => {
  return (
    <Box sx={{ width: '33%' }}>
      <Paper sx={{ marginTop: 2 }}>
        <Box>
          <Box sx={{ padding: '5px 20px' }}><Skeleton animation="wave" width={250} height={30} /></Box>
          <Skeleton animation="wave" variant="circular" width='100%' height={2} />
          <Box className='d-flex justify-between p-20 g-20' sx={{ height: '245px' }}>
            <Box>
              <Skeleton className='m-t-40' animation="wave" width={280} height={30} />
              <Skeleton animation="wave" width={340} height={30} />
              <Skeleton animation="wave" width={380} height={30} />
              <Skeleton animation="wave" width={380} height={30} />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

const SkeletonWithTable = () => {
  const count = 5;
  return (
    <Box sx={{ width: '33%' }}>
      <Paper sx={{ marginTop: 2 }}>
        <Box>
          <Box sx={{ padding: '5px 20px' }}><Skeleton animation="wave" width={250} height={30} /></Box>
          <Skeleton animation="wave" variant="circular" width='100%' height={2} />
          <Box sx={{ height: '245px', display: 'flex', flexDirection: 'column', gap: 1, padding: '20px' }}>
            {Array.from({ length: count }).map((_, index) => (
              <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Skeleton animation="wave" width={130} height={30} />
                <Skeleton animation="wave" width={130} height={30} />
                <Skeleton animation="wave" width={130} height={30} />
              </Box>
            ))}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

const SkeletonTableLoading = () => {
  const count = 2;
  return (
    <Box sx={{ width: '100%' }}>
      <Box className='m-t-40'><Skeleton animation="wave" width={250} height={30} /></Box>
      <Paper>
          <Box  className="m-t-20" sx={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
            {Array.from({ length: count }).map((_, index) => (
              <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Skeleton animation="wave" width={200} height={30} />
                <Skeleton animation="wave" width={200} height={30} />
                <Skeleton animation="wave" width={200} height={30} />
                <Skeleton animation="wave" width={200} height={30} />
                <Skeleton animation="wave" width={200} height={30} />
              </Box>
            ))}
        </Box>
      </Paper>
    </Box>
  );
};

export { SkeletonTopLoading, SkeletonBodyLoading, SkeletonTableLoading };