import api from './interceptor'

export const getTotalBaseInfromation = async () => {
    try {
        const response = await api.get(`input/home/get_base_information`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getAllCompanyStatus = async () => {
    try {
        const response = await api.get(`input/home/get_company_status`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getComapnyModules = async () => {
    try {
        const response = await api.get(`input/home/get_company_modules`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getAllChildCompany = async () => {
    try {
        const response = await api.get(`input/home/get_child_companies`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const addCompany = async (company_data) => {
    try {
        const response = await api.post(`input/home/add_company`, company_data);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getDevicesInformation = async () => {
    try {
        const response = await api.get(`input/home/get_device_data`, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getCompanyTotalData = async () => {
    try {
        const response = await api.get(`input/home/get/total_data`, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getCompanyProfileCount = async () => {
    try {
        const response = await api.get(`input/home/get_total_count`, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}


export const getTabsModuleInformation = async () => {
    try {
        const response = await api.get(`input/home/get_tabs_module_information`, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getReportProcessInformation = async () => {
    try {
        const response = await api.get(`input/home/get_process_reports`, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getOneCompanyDetails = async () => {
    try {
        const response = await api.get(`input/home/one_company_details`, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const AddManualDataEntry = async (payload) => {
    try {
        const response = await api.post(`input/home/manual_data_entry`, payload, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getAlertCounts = async () => {
    try {
        const response = await api.get(`input/home/get_alert_data`, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}