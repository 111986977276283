import axios from 'axios';

const url = window.config.endpoint;

const api = axios.create({
    baseURL: url,
});

api.defaults.withCredentials = true;

api.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        config.headers['Content-Type'] = 'application/json'
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        const original_request = error.config;
        // If the error is 401 and it's not the token refresh request itself
        if (error?.response?.status === 401 && !original_request._retry) {
            original_request._retry = true;

            try {
                const refreshed_jwt_token = await getRefreshedTokenId();
                localStorage.setItem('token', refreshed_jwt_token);

                // Update the original request with the new token
                original_request.headers.Authorization = `Bearer ${refreshed_jwt_token}`;

                // Retry the original request
                return axios(original_request);
            } catch (refreshError) {
                localStorage.clear()
                window.location.reload()
            }
        }
        return Promise.reject(error);
    }
);

export const getRefreshedTokenId = async () => {
    try {
        const response = await axios({
            method: 'POST',
            url: `${window.config.endpoint}authenticate/v1/refresh-token`,
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                version: 'v4'
            },
            withCredentials: true
        });
        return response.data.jwtToken;
    } catch (error) {
        throw error;
    }
};

export default api;
