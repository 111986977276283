import { Box, Button, IconButton, Modal, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { checkValidPhoneNumber } from '../../common/common';
import { errorMessage, successMessage } from '../../common/Toast';
import { addNewRetailerData } from '../../utils/api.retailer';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  border: '2px solid aliceblue',
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

function AddNewRetailer({ open_model, setOpenModel, setSuccessResponse }) {
  const [name, setName] = useState('')
  const [phone_number, setPhoneNumber] = useState('')
  const [retailer_id, setRetailerId] = useState('')
  const [location, setLocation] = useState('')
  const [is_check_valid_number, setIsCheckValidNumber] = useState(true)

  const handleClose = () => {
    setOpenModel(false)
    clear()
  };

  const onHandleSubmit = async (event) => {
    event.preventDefault()
    const data = {
      retailer_name: name,
      phone_no: phone_number,
      retailer_id: retailer_id,
      location: location
    }
    try {
      const add_user_response = await addNewRetailerData(data)
      if (add_user_response.status === 200) {
        clear()
        setSuccessResponse(true)
        successMessage(`${name} added successfully.`)
      }
      if (add_user_response?.response?.data?.error === 'Invalid User ID') {
        errorMessage('Phone number is already exist.')
      }
    } catch (error) {
      errorMessage(error?.response?.data?.message)
    } finally {
      // clear()
    }
  }

  const onHandlePhoneNumber = (event) => {
    const phoneNo = event.target.value;
    if (checkValidPhoneNumber(phoneNo)) {
      setPhoneNumber(phoneNo);
      setIsCheckValidNumber(true);
    } else {
      setPhoneNumber('');
      setIsCheckValidNumber(false);
    }
    if (phoneNo === '') {
      setIsCheckValidNumber(true);
    }
  }

  const clear = () => {
    setName('')
    setPhoneNumber('')
    setLocation('')
    setRetailerId('')
    setOpenModel(false)
    setIsCheckValidNumber(true)
  }

  return (
    <div>
      <Modal
        open={open_model}
        onClose={handleClose}
      >
        <Box sx={style}>
          <div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" component="h2">
              <strong>Add Retailer</strong>
            </Typography>

            <Box>
              <div>
                <form onSubmit={onHandleSubmit}>
                  <div>
                    <TextField
                      required
                      className='w-100'
                      label="Name"
                      type='text'
                      style={{ border: '1px solid aliceblue', marginTop: '10px' }}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div>
                    <TextField
                      className='w-100'
                      label="Retailer Id"
                      type='text'
                      style={{ border: '1px solid aliceblue', marginTop: '10px' }}
                      onChange={(e) => setRetailerId(e.target.value)}
                    />
                  </div>
                  <div>
                    <TextField
                      required
                      className='w-100'
                      label="Phone Number"
                      type='tel_no'
                      inputProps={{ maxLength: 13 }}
                      onChange={(e) => onHandlePhoneNumber(e)}
                      style={{ border: '1px solid aliceblue', marginTop: '10px' }}
                    />
                  </div>
                  <div>
                    <TextField
                      required
                      className='w-100'
                      label="Location"
                      type='text'
                      style={{ border: '1px solid aliceblue', marginTop: '10px' }}
                      onChange={(e) => setLocation(e.target.value)}
                    />
                  </div>
                  <div>
                    <p style={{ color: 'red' }}>{
                      !is_check_valid_number && !phone_number ? 'Please enter valid 10 digit number start with +91.' : ''
                    }</p>
                  </div>
                  <Box className='d-flex justify-end g-5 m-t-10'>
                    <Button variant='outlined' onClick={handleClose} >Cancel</Button>
                    <Button variant='outlined' disabled={!is_check_valid_number} type='submit' >Submit</Button>
                  </Box>
                </form>
              </div>

            </Box>

          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default AddNewRetailer