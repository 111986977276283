import './App.css';
import AppRoute from './routes/AppRoute';

function App() {
  return (
    <div className="App">
      <AppRoute/>
    </div>
  );
}

export default App;
