import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { getTotalBaseInfromation } from '../../utils/api.home';
import { SkeletonTopLoading } from '../../widgets/SkeltonEffect';

export default function TotalInformation() {
  const [base_info, setBaseInfo] = useState([]);
  const [is_loading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await getTotalBaseInfromation();
      if (response?.data?.data) {
        const info = [
          { title: "Env", count: window.config.endpoint.includes('localhost') ? 'test' : 'prod' },
          ...response?.data?.data?.info
        ]
        setBaseInfo(info);
      }
      setIsLoading(false);
    })()
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: '#6ca0dc',
        borderRadius: 2,
        width: '100%',
      }}
    >
      {is_loading ?
        <Box>
          <SkeletonTopLoading />
        </Box>
        : <Grid container spacing={2}>
          {base_info.length !== 0 && base_info.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={index}
              sx={{
                borderBottom: index < 4 ? '1px solid white' : 'none',
                padding: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  textAlign: 'left',
                  borderRight: (index + 1) % 4 !== 0 ? '1px solid white' : 'none',
                  padding: (index) % 4 === 0 ? '0 20px' : 'none',
                  width: '100%',
                }}
              >
                <Typography variant="h6" className='white' >
                  {item.title ? item.title : ''}
                </Typography>
                <Typography variant="h5" className='fw-bold white'>
                  {item.count ? item.count : '0'}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>}
    </Box>
  );
}