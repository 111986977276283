import { Box, Button, FormControl, IconButton, Input, InputAdornment, InputLabel } from '@mui/material';
import React, { useState } from 'react';
import { getCrateSearchDetails } from '../utils/api.reports';
import { MdClear } from "react-icons/md";
import RotatingLoading from '../widgets/RotatingLoading';

function CrateSearchEngine() {
    const [crate_id, setCrateID] = useState('');
    const [crate_search_result, setCrateSearchResult] = useState(null);
    const [loading, setLoading] = useState(false);

    const onShowCrateDetails = async () => {
        try {
            setLoading(true);
            setCrateSearchResult(null);
            const filter_data = { crate_id: crate_id.trim(), company_name: localStorage.getItem('company_name') };
            if (crate_id.trim()) {
                const response = await getCrateSearchDetails(filter_data);
                if (response?.data?.data) {
                    setCrateSearchResult({
                        production_data: response?.data?.data?.production_data,
                        dispatch_data: response?.data?.data?.dispatch_data,
                        empty_data: response?.data?.data?.empty_data,
                        retailer_data: response?.data?.data?.retailer_data
                    });
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const onHandleClear = () => {
        setCrateID('');
        setCrateSearchResult(null);
    };

    return (
        <Box className='w-100 h-80vh'>
            <Box className="d-flex justify-center m-t-30">
                <Box className='d-flex justify-between w-80'>
                    <FormControl className='w-100' variant="standard">
                        <InputLabel>Search By Crate ID</InputLabel>
                        <Input
                            type='text'
                            value={crate_id}
                            onChange={(e) => setCrateID(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={onHandleClear}>
                                        <MdClear />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <Box className='w-20'></Box>
                    <Button //Header-Background 
                        className='w-40'
                        variant="contained"
                        style={{ color: 'white', background: 'linear-gradient(160deg, #2a2666, #A5D7FF)' }}
                        onClick={onShowCrateDetails}
                        disabled={loading}
                    >
                        {loading ? 'Searching...' : 'Show'}
                    </Button>
                </Box>
            </Box>
            <Box>
                {loading
                    ? <Box><RotatingLoading /></Box>
                    : crate_search_result && (
                        <Box>
                            <Box className="m-t-50 d-flex justify-center w-100">
                                <p className='fw-bold fs-20 m-b-10 underline APP-COLOR'>
                                    Result for Crate ID: {crate_id ? crate_id : 'N/A'}
                                </p>
                            </Box>
                            <Box className="m-t-10 d-flex justify-center">
                                <Box className='w-100'>
                                    <Box className='d-flex justify-between m-t-30' style={{ height: 200 }}>
                                        <Box className='w-50 d-flex justify-center align-center flex-direction-column'>
                                            <Box>
                                                <p className='fw-bold fs-20 m-b-10 APP-COLOR'>Production Module</p>
                                                {crate_search_result.production_data === null
                                                    ? <Box><p className='m-b-10 red'>Error: No Data Present On Production</p></Box>
                                                    : <Box>
                                                        <p><span className='fw-500'>Product Name: </span><span>{crate_search_result?.production_data?.product}</span></p>
                                                        <p><span className='fw-500'>Pack Size: </span><span>{crate_search_result?.production_data?.pack_size}</span></p>
                                                        <p><span className='fw-500'>Date: </span><span>{crate_search_result?.production_data?.timestamp}</span></p>
                                                        <p><span className='fw-500'>Crate ID: </span><span>{crate_search_result?.production_data?.crate_id}</span></p>
                                                    </Box>
                                                }
                                            </Box>
                                        </Box>
                                        <Box className='w-50 d-flex justify-center align-center flex-direction-column'>
                                            <Box>
                                                <p className='fw-bold fs-20 m-b-10 APP-COLOR'>Dispatch Module</p>
                                                {crate_search_result?.dispatch_data === null
                                                    ? <Box><p className='m-b-10 red'>Error: No Data Present On Dispatch</p></Box>
                                                    : <Box>
                                                        <p><span className='fw-500'>Product Name: </span><span>{crate_search_result?.dispatch_data?.product}</span></p>
                                                        <p><span className='fw-500'>Pack Size: </span><span>{crate_search_result?.dispatch_data?.pack_size}</span></p>
                                                        <p><span className='fw-500'>Date: </span><span>{crate_search_result?.dispatch_data?.timestamp}</span></p>
                                                        <p><span className='fw-500'>Vehicle No: </span><span>{crate_search_result?.dispatch_data?.vehicle_no}</span></p>
                                                        <p><span className='fw-500'>Crate ID: </span><span>{crate_search_result?.dispatch_data?.crate_id}</span></p>
                                                    </Box>
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className='d-flex justify-between m-t-20'>
                                        <Box className='w-50 d-flex justify-center align-center flex-direction-column'>
                                            <Box>
                                                <p className='fw-bold fs-20 m-b-10 APP-COLOR'>Empty Module</p>
                                                {crate_search_result?.empty_data === null || crate_search_result?.empty_data === undefined
                                                    ? <Box><p className='m-b-10 red'>Error: No Data Present On Empty</p></Box>
                                                    : <Box>
                                                        <p><span className='fw-500'>Product Name: </span><span>{crate_search_result?.empty_data?.product}</span></p>
                                                        <p><span className='fw-500'>Pack Size: </span><span>{crate_search_result?.empty_data?.pack_size}</span></p>
                                                        <p><span className='fw-500'>Date: </span><span>{crate_search_result?.empty_data?.timestamp}</span></p>
                                                        <p><span className='fw-500'>Vehicle No: </span><span>{crate_search_result?.empty_data?.vehicle_no}</span></p>
                                                        <p><span className='fw-500'>Crate ID: </span><span>{crate_search_result?.empty_data?.crate_id}</span></p>
                                                    </Box>
                                                }
                                            </Box>
                                        </Box>
                                        <Box className='w-50 d-flex justify-center align-center flex-direction-column'>
                                            <Box>
                                                <p className='fw-bold fs-20 m-b-10 APP-COLOR'>Retailer Module</p>
                                                {crate_search_result?.retailer_data === null || crate_search_result?.retailer_data === undefined
                                                    ? <Box><p className='m-b-10 red'>Error: No Data Present On Retailer</p></Box>
                                                    : <Box>
                                                        <p><span className='fw-500'>Retailer Name: </span><span>{crate_search_result?.retailer_data?.retailer_name}</span></p>
                                                        <p><span className='fw-500'>Phone No: </span><span>{crate_search_result?.retailer_data?.phone_no}</span></p>
                                                        <p><span className='fw-500'>Location: </span><span>{crate_search_result?.retailer_data?.location}</span></p>
                                                    </Box>
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )}
            </Box>
        </Box>
    );
}

export default CrateSearchEngine;