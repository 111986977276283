import React from 'react';
import Chart from 'react-apexcharts';

const PieChart = ({title, series}) => {

  const options = {
    colors: ['#A5D7FF', '#6CA0DC'],
    labels: title,
    legend: {
      position: 'top', // Position the legend at the bottom
      horizontalAlign: 'left', // Center align the legend
      floating: false, // Make sure the legend is not floating
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 50,
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
          },
        },
      },
    ],
  };

  return <Chart options={options} series={series} type="pie" />;
};

export default PieChart;