import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { AccountCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function ProfileMenuList() {
    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onHandleUserDetail = () => {
        navigate('/dashboard/user_detail')
    }

    const logout = async () => {
        localStorage.clear()
        navigate('/login')
    }

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Profile">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar src='' sx={{ width: 32, height: 32 }} />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Box className='d-flex justify-center flex-direction-column' style={{ minWidth: 250 }}>
                    <p className='m-l-20 fs-16 fw-bold'>{localStorage.getItem('name')}</p>
                    <p className='m-l-20 fs-12'>Role: {localStorage.getItem('role')}</p>
                </Box>
                <Divider className='m-t-10' />
                <MenuItem onClick={() => onHandleUserDetail()}>
                    <Box className='d-flex justify-center fs-12' >
                        <AccountCircle sx={{ fontSize: 16, marginTop: 0.7 }} />
                        <p className='fs-12 m-l-20' style={{ marginTop:4  }}>User Profile</p>
                    </Box>
                </MenuItem>
                <MenuItem onClick={() => logout()}>
                    <Box className='d-flex justify-center fs-12' >
                        <Logout sx={{ fontSize: 16, marginTop: 0.7 }} />
                        <p className='fs-12 m-l-20' style={{ marginTop:4  }}>Logout</p>
                    </Box>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}