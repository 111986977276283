import React, { useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, TablePagination } from '@mui/material';
import { showDateAndTime } from '../../common/common';

function DisplayDeliveryChallan() {
  const tableRows = []
  const headers = [
    'Date',
    'Time',
    'Challan ID',
    'Vehicle No',
    'Product Count',
    'Driver Name',
    'Phone No'
  ];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className='m-a-20 m-t-30'>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650, borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell className="border fw-bold" key={header} align="left">{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows.length ? tableRows.map((row, index) => (
                <TableRow key={row.index}>
                  <TableCell component="th" scope="row" className='border' align='left'>{showDateAndTime(row.date).date}</TableCell>
                  <TableCell className='border' align="left">{showDateAndTime(row.date).time}</TableCell>
                  <TableCell className='border' align="left">{row.challan_id}</TableCell>
                  <TableCell className='border' align="left">{row.vehicle_no}</TableCell>
                  <TableCell className='border' align="left">{row.product_count}</TableCell>
                  <TableCell className='border' align="left">{row.driver_name}</TableCell>
                  <TableCell className='border' align="left">{row.phone_no}</TableCell>
                </TableRow>
              )) :
                <TableRow>
                  <TableCell className='p-10 headers' colSpan={headers.length} align="center">No Records Found</TableCell>
                </TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={tableRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
    </div>
  )
}

export default DisplayDeliveryChallan