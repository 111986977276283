import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import { getComapnyModules } from '../../utils/api.home';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useCompanyContext } from '../../common/companyContext';

export default function Company() {
  const navigate = useNavigate()
  const { updateSelectedCompany } = useCompanyContext();
  const [company_data, setCompanyData] = useState([])
  const [module_counts, setModuleCounts] = useState([])

  useEffect(() => {
    (async () => {
      const response = await getComapnyModules()
      if (response?.data?.data) {
        setCompanyData(response?.data?.data?.company_modules)
        response?.data?.data && setModuleCounts(response?.data?.data?.module_count[0])
      }
    })()
  }, [])

  const onHandleCompanyDetails = (company_name) => {
    navigate('/dashboard/company/' + company_name)
    localStorage.setItem('company_name', company_name)
    updateSelectedCompany(company_name)
  }

  return (
    <Box className='m-a-30'>
      <Box className="d-flex g-10">
        <ArrowBackIcon className='m-t-5 cursor' onClick={() => navigate(-1)}/>
        <h2>Company</h2>
      </Box>
      <Box className='m-t-15'>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell className="border fw-bold" align="left">Company Name</TableCell>
                <TableCell className="border fw-bold" align="left">Empty  ({module_counts.reception_count})</TableCell>
                <TableCell className="border fw-bold" align="left">Production ({module_counts.production_count})</TableCell>
                <TableCell className="border fw-bold" align="left">Dispatch ({module_counts.dispatch_count})</TableCell>
                <TableCell className="border fw-bold" align="left">Retailer ({module_counts.retailer_count})</TableCell>
                <TableCell className="border fw-bold" align="left">Weight</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {company_data && company_data.map((row) => (
                <TableRow
                  key={row.id}
                // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align='left' className='cursor underline blue'
                    onClick={() => onHandleCompanyDetails(row.company_name)}
                  >{row.company_name}
                  </TableCell>
                  <TableCell className="border" align="left">{row.reception && <CheckCircleIcon color='success' />}</TableCell>
                  <TableCell className="border" align="left">{row.production && <CheckCircleIcon color='success' />}</TableCell>
                  <TableCell className="border" align="left">{row.dispatch && <CheckCircleIcon color='success' />}</TableCell>
                  <TableCell className="border" align="left">{row.retailer && <CheckCircleIcon color='success' />}</TableCell>
                  <TableCell className="border" align="left">{row.weight && <CheckCircleIcon color='success' />}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
