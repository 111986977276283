import { TableCell, TableRow } from "@mui/material";
import { showDateAndTime } from "./common/common";
import { getImageUrl } from "./utils/api.reports";

// Production Log
export const productionLogHeaders = [
  'Date',
  'Time',
  'Shift',
  'Line No.',
  'User Name',
  'Crate Count',
  'Product',
  'Packet Size',
  'No Of Pouches',
  'Weight',
  'Top Image Link',
  'Side Image Link'
];

export const productionLogTableRows = (data) => {
  return (
    data && data.map((row) => (
      <TableRow key={row.id}>
        <TableCell component="th" scope="row" className='border' align='left'>{showDateAndTime(row.date).date}</TableCell>
        <TableCell className='border' align="left">{showDateAndTime(row.date).time}</TableCell>
        <TableCell className='border' align="left">{row.shift}</TableCell>
        <TableCell className='border' align="left">{row.line}</TableCell>
        <TableCell className='border' align="left">{row.user_id}</TableCell>
        <TableCell className='border' align="left">{row.crate_count}</TableCell>
        <TableCell className='border' align="left">{row.product}</TableCell>
        <TableCell className='border' align="left">{row.pack_size}</TableCell>
        <TableCell className='border' align="left">{row.no_of_pouches}</TableCell>
        <TableCell className='border' align="left">{row.weight}</TableCell>
        <TableCell className='border blue cursor' align="left" onClick={() => getImageUrl(row.top_image_link)}>{row.top_image_link}</TableCell>
        <TableCell className='border blue cursor' align="left" onClick={() => getImageUrl(row.side_image_link)}>{row.side_image_link}</TableCell>
      </TableRow>
    ))
  )
}

// production Summary
export const productionSummaryHeaders = [
  'Date',
  'Shift',
  'Line No.',
  'Product Name',
  'Pack Size',
  'Total Crates',
  'Cold Storage Count',
  'Sub Total',
  'Total Pouches',
  'Average No of Pouches',
  'Total Weight in Litre',
  'Average Crate Weight'
];

export const productionSummaryTableRows = (data) => {
  return (
    data && data.map((row) => (
      <TableRow key={row.id}>
        <TableCell component="th" scope="row" className='border' align='left'>{showDateAndTime(row.date).date}</TableCell>
        <TableCell className='border' align="left">{row.shift}</TableCell>
        <TableCell className='border' align="left">{row.line}</TableCell>
        <TableCell className='border' align="left">{row.product}</TableCell>
        <TableCell className='border' align="left">{row.pack_size}</TableCell>
        <TableCell className='border' align="left">{row.total_crate}</TableCell>
        <TableCell className='border' align="left">{row.cold_storage_count}</TableCell>
        <TableCell className='border' align="left">{row.sub_total}</TableCell>
        <TableCell className='border' align="left">{row.total_pouches}</TableCell>
        <TableCell className='border' align="left">{row.average_no_of_pouches}</TableCell>
        <TableCell className='border' align="left">{row.total_weight}</TableCell>
        <TableCell className='border' align="left">{row.average_crate_weight}</TableCell>
      </TableRow>
    ))
  )
}

// Empty Log
export const ReceptionLogHeaders = [
  'Date',
  'Time',
  'Shift',
  'user Name',
  'Driver Name',
  'Salesman Name',
  'Vehicle No.',
  'Crate Count',
  'Address',
  'Crate ID',
  'Image Link',
];

export const ReceptionLogTableRows = (data) => {
  return (
    data && data.map((row) => (
      <TableRow key={row.id}>
        <TableCell component="th" scope="row" className='border' align='left'>{showDateAndTime(row.date).date}</TableCell>
        <TableCell className='border' align="left">{showDateAndTime(row.date).time}</TableCell>
        <TableCell className='border' align="left">{row.shift}</TableCell>
        <TableCell className='border' align="left">{row.user_name}</TableCell>
        <TableCell className='border' align="left">{row.driver_name}</TableCell>
        <TableCell className='border' align="left">{row.salesman_name}</TableCell>
        <TableCell className='border' align="left">{row.vehicle_no}</TableCell>
        <TableCell className='border' align="left">{row.crate_count}</TableCell>
        <TableCell className='border' align="left">{row.address}</TableCell>
        <TableCell className='border' align="left">{row.scan_data_id}</TableCell>
        <TableCell className='border blue cursor' align="left" onClick={() => getImageUrl(row.image_key)}>{row.image_key}</TableCell>
      </TableRow>
    ))
  )
}

// Empty Summary
export const ReceptionSummaryHeaders = [
  'Date',
  "Time",
  'Shift',
  'Vehicle No.',
  'Crate Count',
  'No Of Scans'
];

export const ReceptionSummaryTableRows = (data) => {
  return (
    data && data.map((row) => (
      <TableRow key={row.id}>
        <TableCell component="th" scope="row" className='border' align='left'>{showDateAndTime(row.date).date}</TableCell>
        <TableCell className='border' align="left">{showDateAndTime(row.date).time}</TableCell>
        <TableCell className='border' align="left">{row.shift}</TableCell>
        <TableCell className='border' align="left">{row.vehicle_no}</TableCell>
        <TableCell className='border' align="left">{row.total_crate_count}</TableCell>
        <TableCell className='border' align="left">{row.total_no_of_scans}</TableCell>
      </TableRow>
    ))
  )
}

// Dispatch Android Log
export const DispatchAndroidLogHeaders = [
  'Date',
  'Time',
  'Shift',
  'user Name',
  'Driver Name',
  'Salesman Name',
  'Vehicle No.',
  'Crate Count',
  'Address',
  'Crate ID',
  'Image Link',
];

export const DispatchAndroidLogTableRows = (data) => {
  return (
    data && data.map((row) => (
      <TableRow key={row.id}>
        <TableCell component="th" scope="row" className='border' align='left'>{showDateAndTime(row.date).date}</TableCell>
        <TableCell className='border' align="left">{showDateAndTime(row.date).time}</TableCell>
        <TableCell className='border' align="left">{row.shift}</TableCell>
        <TableCell className='border' align="left">{row.user_name}</TableCell>
        <TableCell className='border' align="left">{row.driver_name}</TableCell>
        <TableCell className='border' align="left">{row.salesman_name}</TableCell>
        <TableCell className='border' align="left">{row.vehicle_no}</TableCell>
        <TableCell className='border' align="left">{row.crate_count}</TableCell>
        <TableCell className='border' align="left">{row.address}</TableCell>
        <TableCell className='border' align="left">{row.scan_data_id}</TableCell>
        <TableCell className='border blue cursor' align="left" onClick={() => getImageUrl(row.image_key)}>{row.image_key}</TableCell>
      </TableRow>
    ))
  )
}

// Dispatch Android Summary
export const DispatchAndroidSummaryHeaders = [
  'Date',
  "Time",
  'Shift',
  'Vehicle No.',
  'Crate Count',
  'No Of Scans'
];

export const DispatchAndroidSummaryTableRows = (data) => {
  return (
    data && data.map((row) => (
      <TableRow key={row.id}>
        <TableCell component="th" scope="row" className='border' align='left'>{showDateAndTime(row.date).date}</TableCell>
        <TableCell className='border' align="left">{showDateAndTime(row.date).time}</TableCell>
        <TableCell className='border' align="left">{row.shift}</TableCell>
        <TableCell className='border' align="left">{row.vehicle_no}</TableCell>
        <TableCell className='border' align="left">{row.total_crate_count}</TableCell>
        <TableCell className='border' align="left">{row.total_no_of_scans}</TableCell>
      </TableRow>
    ))
  )
}

// Dispatch Industrial Camera Log
export const DispatchIndustrialCameraLogHeaders = [
  'Date',
  'Time',
  'Shift',
  'Dispatch Bay',
  'user Name',
  'Driver Name',
  'Salesman Name',
  'Vehicle No.',
  'Product',
  'Pack Size',
  'Crate Count',
  'No. of Pouches',
  'Weight',
  'Top Image Link',
  'Side Image Link',
];

export const DispatchIndustrialCameraLogTableRows = (data) => {
  return (
    data && data.map((row) => (
      <TableRow key={row.id}>
        <TableCell component="th" scope="row" className='border' align='left'>{showDateAndTime(row.date).date}</TableCell>
        <TableCell className='border' align="left">{showDateAndTime(row.date).time}</TableCell>
        <TableCell className='border' align="left">{row.shift}</TableCell>
        <TableCell className='border' align="left">{row.dispatch_bay}</TableCell>
        <TableCell className='border' align="left">{row.user_name}</TableCell>
        <TableCell className='border' align="left">{row.driver_name}</TableCell>
        <TableCell className='border' align="left">{row.salesman_name}</TableCell>
        <TableCell className='border' align="left">{row.vehicle_no}</TableCell>
        <TableCell className='border' align="left">{row.product}</TableCell>
        <TableCell className='border' align="left">{row.pack_size}</TableCell>
        <TableCell className='border' align="left">{row.crate_count}</TableCell>
        <TableCell className='border' align="left">{row.no_of_pouches}</TableCell>
        <TableCell className='border' align="left">{row.weight}</TableCell>
        <TableCell className='border blue cursor' align="left" onClick={() => getImageUrl(row.top_image_link)}>{row.top_image_link}</TableCell>
        <TableCell className='border blue cursor' align="left" onClick={() => getImageUrl(row.side_image_link)}>{row.side_image_link}</TableCell>
      </TableRow>
    ))
  )
}

// Dispatch Industrial Camera Summary
export const DispatchIndustrialCameraSummaryHeaders = [
  'Date',
  'Shift',
  'Dispatch Bay',
  'Vehicle No.',
  'Product',
  'Pack Size',
  'Total Crates',
  'Average No. of Pouches',
  'Average Weight Per Crate'
];

export const DispatchIndustrialCameraSummaryTableRows = (data) => {
  return (
    data && data.map((row) => (
      <TableRow key={row.id}>
        <TableCell component="th" scope="row" className='border' align='left'>{showDateAndTime(row.date).date}</TableCell>
        <TableCell className='border' align="left">{row.shift}</TableCell>
        <TableCell className='border' align="left">{row.dispatch_bay}</TableCell>
        <TableCell className='border' align="left">{row.vehicle_no}</TableCell>
        <TableCell className='border' align="left">{row.product}</TableCell>
        <TableCell className='border' align="left">{row.pack_size}</TableCell>
        <TableCell className='border' align="left">{row.total_crate}</TableCell>
        <TableCell className='border' align="left">{row.average_no_of_pouches}</TableCell>
        <TableCell className='border' align="left">{row.average_crate_weight}</TableCell>
      </TableRow>
    ))
  )
}

// Scrap Log
export const ScrapLogHeaders = [
  'Date',
  'Time',
  'User Name',
  'Crate Count',
  'Crate ID',
  'Image Key',
]

export const ScrapLogTableRows = (data) => {
  return (
    data && data.map((row) => (
      <TableRow key={row.id}>
        <TableCell component="th" scope="row" className='border' align='left'>{showDateAndTime(row.date).date}</TableCell>
        <TableCell className='border' align="left">{showDateAndTime(row.date).time}</TableCell>
        <TableCell className='border' align="left">{row.user_name}</TableCell>
        <TableCell className='border' align="left">{row.crate_count}</TableCell>
        <TableCell className='border' align="left">{row.crate_id}</TableCell>
        <TableCell className='border blue cursor' align="left" onClick={() => getImageUrl(row.image_key)}>{row.image_key}</TableCell>
      </TableRow>
    ))
  )
}

// leaky log
export const LeakyLogHeaders = [
  'Date',
  'Time',
  'User Name',
  'Driver Name',
  'Vehicle No.',
  'Address',
  'Crate Count',
  'Crate Id',
  'Top Image Key',
  'Side Image Key'
]

export const LeakyLogTableRows = (data) => {
  return (
    data && data.map((row) => (
      <TableRow key={row.id}>
        <TableCell component="th" scope="row" className='border' align='left'>{showDateAndTime(row.date).date}</TableCell>
        <TableCell className='border' align="left">{showDateAndTime(row.date).time}</TableCell>
        <TableCell className='border' align="left">{row.user_id}</TableCell>
        <TableCell className='border' align="left">{row.driver_id}</TableCell>
        <TableCell className='border' align="left">{row.vehicle_no}</TableCell>
        <TableCell className='border' align="left">{row.address}</TableCell>
        <TableCell className='border' align="left">{row.crate_count}</TableCell>
        <TableCell className='border' align="left">{row.crate_id}</TableCell>
        <TableCell className='border blue cursor' align="left" onClick={() => getImageUrl(row.top_image_key)}>{row.top_image_key}</TableCell>
        <TableCell className='border blue cursor' align="left" onClick={() => getImageUrl(row.side_image_key)}>{row.side_image_key}</TableCell>
      </TableRow>
    ))
  )
}

// New Crate Log
export const NewCrateLogHeaders = [
  'Date',
  'Time,',
  'User Name',
  'Phone No.',
  'Crate ID',
  'Weight',
  'Front Image',
  'Back Image',
  'Reference Image  '
]

export const NewCrateLogTableRows = (data) => {
  return (
    data && data.map((row) => (
      <TableRow key={row.id}>
        <TableCell component="th" scope="row" className='border' align='left'>{showDateAndTime(row.date).date}</TableCell>
        <TableCell className='border' align="left">{showDateAndTime(row.date).time}</TableCell>
        <TableCell className='border' align="left">{row.user_name}</TableCell>
        <TableCell className='border' align="left">{row.phone_no}</TableCell>
        <TableCell className='border' align="left">{row.crate_id}</TableCell>
        <TableCell className='border' align="left">{row.weight}</TableCell>
        <TableCell className='border' align="left blue cursor" onClick={() => getImageUrl(row.front_image_link, true)}>{row.front_image_link}</TableCell>
        <TableCell className='border' align="left blue cursor" onClick={() => getImageUrl(row.back_image_link, true)}>{row.back_image_link}</TableCell>
        <TableCell className='border' align="left blue cursor" onClick={() => getImageUrl(row.reference_image_link, true)}>{row.reference_image_link}</TableCell>
      </TableRow>
    ))
  )
}

// all Crate ids
export const AllCrateIdsHeaders = [
  'Crate Id',
  'Status'
]

export const AllCrateIdsTableRows = (data) => {
  return (
    data && data.map((row) => (
      <TableRow key={row.id}>
        <TableCell component="th" scope="row" className='border' align='left'>{row.crate_id}</TableCell>
        <TableCell className='border' align="left">{row.status}</TableCell>
      </TableRow>
    ))
  )
}

// Retailer Log
export const RetailerLogHeaders = [
  'Date',
  'Time',
  'Operation',
  'Retailer Name',
  'Driver Name',
  'Vehicle No.',
  'Crate Count',
  'Address',
  'Crate Id',
  'Shift',
  'Image Link'
];

export const RetailerLogTableRows = (data) => {
  return (
    data && data.map((row) => (
      <TableRow key={row.id}>
        <TableCell component="th" scope="row" className='border' align='left'>{showDateAndTime(row.date).date}</TableCell>
        <TableCell className='border' align="left">{showDateAndTime(row.date).time}</TableCell>
        <TableCell className='border' align="left">{row.operation}</TableCell>
        <TableCell className='border' align="left">{row.retailer_name}</TableCell>
        <TableCell className='border' align="left">{row.driver_name}</TableCell>
        <TableCell className='border' align="left">{row.vehicle_no}</TableCell>
        <TableCell className='border' align="left">{row.crate_count}</TableCell>
        <TableCell className='border' align="left">{row.address}</TableCell>
        <TableCell className='border' align="left">{row.crate_id}</TableCell>
        <TableCell className='border' align="left">{row.shift}</TableCell>
        <TableCell className='border' align="left" onClick={() => getImageUrl(row.image_link)}>{row.image_link}</TableCell>
      </TableRow>
    ))
  )
}

// Retailer Summary
export const RetailerSummaryHeaders = [
  'Date',
  "Time",
  'Shift',
  'Operation',
  'Vehicle No',
  'No Of Scans',
  'Crate Count',
];

export const RetailerSummaryTableRows = (data) => {
  return (
    data && data.map((row) => (
      <TableRow key={row.id}>
        <TableCell component="th" scope="row" className='border' align='left'>{showDateAndTime(row.date).date}</TableCell>
        <TableCell className='border' align="left">{showDateAndTime(row.date).time}</TableCell>
        <TableCell className='border' align="left">{row.shift}</TableCell>
        <TableCell className='border' align="left">{row.operation}</TableCell>
        <TableCell className='border' align="left">{row.vehicle_no}</TableCell>
        <TableCell className='border' align="left">{row.no_of_scans}</TableCell>
        <TableCell className='border' align="left">{row.crate_count}</TableCell>
      </TableRow>
    ))
  )
}

// Rejected Crate Log
export const RejectedCrateLogHeaders = [
  'Date',
  'Time',
  'Shift',
  'Line No.',
  'User Name',
  'Crate Count',
  'Crate Id',
  'Product',
  'Packet Size',
  'No Of Pouches',
  'Weight',
  'Weight Status',
  'Top Image Link',
  'Side Image Link'
]

export const RejectedCrateLogTableRows = (data) => {
  return (
    data && data.map((row) => (
      <TableRow key={row.id}>
        <TableCell component="th" scope="row" className='border' align='left'>{showDateAndTime(row.date).date}</TableCell>
        <TableCell className='border' align="left">{showDateAndTime(row.date).time}</TableCell>
        <TableCell className='border' align="left">{row.shift}</TableCell>
        <TableCell className='border' align="left">{row.line_no}</TableCell>
        <TableCell className='border' align="left">{row.user_name}</TableCell>
        <TableCell className='border' align="left">{row.crate_count}</TableCell>
        <TableCell className='border' align="left">{row.crate_id}</TableCell>
        <TableCell className='border' align="left">{row.product}</TableCell>
        <TableCell className='border' align="left">{row.packet_size}</TableCell>
        <TableCell className='border' align="left">{row.no_of_pouches}</TableCell>
        <TableCell className='border' align="left">{row.weight}</TableCell>
        <TableCell className='border' align="left">{row.weight_status}</TableCell>
        <TableCell className='border blue cursor' align="left" onClick={() => getImageUrl(row.top_image_link)}>{row.top_image_link}</TableCell>
        <TableCell className='border blue cursor' align="left" onClick={() => getImageUrl(row.side_image_link)}>{row.side_image_link}</TableCell>
      </TableRow>
    ))
  )
}

// Missing Crate Log
export const MissingCrateLogHeaders = [
  'Date',
  'Crate ID',
  'Category',
  'Vehicle No',
  'Driver Name',
  'Driver Phone Number',
  'No of Unidentified Crate',
  'Retailer Name',
  'Retailer Phone Number',
  'Retailer Loaction'
]

export const MissingCrateLogTableRows = (data) => {
  return (
    data && data.map((row) => (
      <TableRow key={row.id}>
        <TableCell className='border' align="left">{showDateAndTime(row.date).date}</TableCell>
        <TableCell className='border' align="left">{row.crate_id}</TableCell>
        <TableCell className='border' align="left">{row.category}</TableCell>
        <TableCell className='border' align="left">{row.vehicle_no}</TableCell>
        <TableCell className='border' align="left">{row.driver_name}</TableCell>
        <TableCell className='border' align="left">{row.driver_phone_no}</TableCell>
        <TableCell className='border' align="left">{row.no_of_unidentified_crate ? row.no_of_unidentified_crate : '-'}</TableCell>
        <TableCell className='border' align="left">{row.retailer_name ? row.retailer_name : '-'}</TableCell>
        <TableCell className='border' align="left">{row.retailer_phone_number ? row.retailer_phone_number : '-'}</TableCell>
        <TableCell className='border' align="left">{row.retailer_location ? row.retailer_location : '-'}</TableCell>
      </TableRow>
    ))
  )
}

// unidentified crate log
export const UnidentifiedCrateLogHeaders = [
  'Date',
  'Crate ID',
  'Category',
  'Vehicle No',
  'Driver Name',
  'Driver Phone Number',
  'No of Unidentified Crate',
  'Retailer Name',
  'Retailer Phone Number',
  'Retailer Loaction'
]

export const UnidentifiedCrateLogTableRows = (data) => {
  return (
    data && data.map((row) => (
      <TableRow key={row.id}>
        <TableCell className='border' align="left">{showDateAndTime(row.date).date}</TableCell>
        <TableCell className='border' align="left">{row.crate_id ? row.crate_id : '-'}</TableCell>
        <TableCell className='border' align="left">{row.category ? row.category : '-'}</TableCell>
        <TableCell className='border' align="left">{row.vehicle_no ? row.vehicle_no : '-'}</TableCell>
        <TableCell className='border' align="left">{row.driver_name ? row.driver_name : '-'}</TableCell>
        <TableCell className='border' align="left">{row.driver_phone_number ? row.driver_phone_number : '-'}</TableCell>
        <TableCell className='border' align="left">{row.no_of_unidentified_crate ? row.no_of_unidentified_crate : '-'}</TableCell>
        <TableCell className='border' align="left">{row.retailer_name ? row.retailer_name : '-'}</TableCell>
        <TableCell className='border' align="left">{row.retailer_phone_number ? row.retailer_phone_number : '-'}</TableCell>
        <TableCell className='border' align="left">{row.retailer_location ? row.retailer_location : '-'}</TableCell>
      </TableRow>
    ))
  )
}

// duplicate crate log
export const DuplicateCrateLogHeaders = [
  'Date',
  'Crate ID',
  'Category',
  'Vehicle No',
  'Driver Name',
  'Driver Phone Number',
  'No of Unidentified Crate',
  'Retailer Name',
  'Retailer Phone Number',
  'Retailer Loaction'
]

export const DuplicateCrateLogTableRows = (data) => {
  return (
    data && data.map((row) => (
      <TableRow key={row.id}>
        <TableCell className='border' align="left">{showDateAndTime(row.date).date}</TableCell>
        <TableCell className='border' align="left">{row.crate_id}</TableCell>
        <TableCell className='border' align="left">{row.category ? row.category : '-'}</TableCell>
        <TableCell className='border' align="left">{row.vehicle_no ? row.vehicle_no : '-'}</TableCell>
        <TableCell className='border' align="left">{row.driver_name ? row.driver_name : '-'}</TableCell>
        <TableCell className='border' align="left">{row.driver_phone_number ? row.driver_phone_number : '-'}</TableCell>
        <TableCell className='border' align="left">{row.no_of_unidentified_crate ? row.no_of_unidentified_crate : '-'}</TableCell>
        <TableCell className='border' align="left">{row.retailer_name ? row.retailer_name : '-'}</TableCell>
        <TableCell className='border' align="left">{row.retailer_phone_number ? row.retailer_phone_number : '-'}</TableCell>
        <TableCell className='border' align="left">{row.retailer_location ? row.retailer_location : '-'}</TableCell>
      </TableRow>
    ))
  )
}