import React, { useEffect, useState } from 'react';
import { TextField, MenuItem, Button, Container, Typography, Grid, Box } from '@mui/material';
import { getProductLineWeightData } from '../../utils/api.reports';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AddManualDataEntry } from '../../utils/api.home';
import { errorMessage, successMessage } from '../../common/Toast';

const AddManualData = () => {
    const [data, setData] = useState([]);
    const [productList, setProductList] = useState([]);
    const [packSizeList, setPackSizeList] = useState([]);
    const [line_data, setLineData] = useState([]);

    const [formData, setFormData] = useState({
        dateTime: dayjs(),
        productName: '',
        packSize: '',
        lineNo: '',
        demandCrates: 0,
        bufferCrates: 0,
        leakageCrates: 0,
    });

    useEffect(() => {
        (async () => {
            const response = await getProductLineWeightData();
            if (response?.data?.data) {
                let products = new Set();
                setData(response?.data?.data?.product_data);
                response?.data?.data?.product_data?.forEach((element) => {
                    products.add(element.product);
                });
                setProductList(Array.from(products));
                setLineData(response?.data?.data?.line_data);
            }
        })();
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        if(name === 'productName') {
            setPackSizeList(data.filter((data) => data.product === value).map((data) => data.pack_size));
        }
    };

    const handleDateChange = (date) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            dateTime: date,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        var payload = {
            date: formData.dateTime.format('YYYY-MM-DDTHH:mm:ss.000Z'),
            product_name: formData.productName.trim(),
            pack_size: formData.packSize.trim(),
            line_no: line_data.filter((data) => data.line === formData.lineNo)[0].id,
            demand_crates: formData.demandCrates,
            leakage_crates: formData.leakageCrates,
            buffer_crates: formData.bufferCrates
        }
        try {
            const response = await AddManualDataEntry(payload);
            if (response?.status === 200) {
                successMessage(response?.data?.message);
                setFormData({
                    dateTime: dayjs(),
                    productName: '',
                    packSize: '',
                    lineNo: '',
                    demandCrates: 0,
                    bufferCrates: 0,
                    leakageCrates: 0
                });
            }
        } catch (error) {
            errorMessage(error?.response?.data?.message);
        }
    };

    return (
        <Container className='m-t-30'>
            <Box className='m-t-20 w-100 d-flex justify-center' >
                <Typography variant="h6" className='fw-bold APP-COLOR-2' component="h6" gutterBottom>
                    Manual Data Entry
                </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
                <Box className='w-100 d-flex justify-center align-center'>
                    <Grid container direction="column" spacing={2} className='w-30 m-t-10'>
                        <Grid item xs={3}>
                            <Box className='w-100'> 
                                <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Date"
                                        value={formData.dateTime}
                                        onChange={handleDateChange}
                                        fullWidth
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                select
                                label="Product Name"
                                name="productName"
                                value={formData.productName}
                                onChange={handleChange}
                                fullWidth
                                required
                            >
                                {productList.map((product, index) => (
                                    <MenuItem key={index} value={product}>
                                        {product}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                select
                                label="Pack Size"
                                name="packSize"
                                value={formData.packSize}
                                onChange={handleChange}
                                disabled={!formData.productName}
                                fullWidth
                                required
                            >
                                {packSizeList.map((packSize, index) => (
                                    <MenuItem key={index} value={packSize}>
                                        {packSize}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                select
                                label="Line No"
                                name="lineNo"
                                value={formData.lineNo}
                                onChange={handleChange}
                                fullWidth
                                required
                            >
                                {line_data.map((line, index) => (
                                    <MenuItem key={index} value={line.line}>
                                        {line.line}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                type="number"
                                label="Demand Crates"
                                name="demandCrates"
                                value={formData.demandCrates ? formData.demandCrates : ''}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                type="number"
                                label="Buffer Crates"
                                name="bufferCrates"
                                value={formData.bufferCrates ? formData.bufferCrates : ''}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                type="number"
                                label="Leakage Crates"
                                name="leakageCrates"
                                value={formData.leakageCrates ? formData.leakageCrates : ''}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Button type="submit" variant="contained" className='APP-BACKGROUND-2' fullWidth>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </Container>
    );
};

export default AddManualData;