import api from './interceptor'

export const getDrivers = async () => {
    try {
        const response = await api.get(`input/driver/get_drivers`, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const addNewDriverData = async (driver_data) => {
    try {
        const response = await api.post(`input/driver/add_driver`,
            driver_data,
            { params: { company_name: localStorage.getItem('company_name') }, }
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const updateDriverData = async (driver_data) => {
    try {
        const response = await api.post(`input/driver/update_driver`, driver_data, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteDriver = async (driver_id) => {
    try {
        const response = await api.post(`input/driver/delete_driver`, {driver_id: driver_id}, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

