import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, TablePagination, TextField, InputAdornment, IconButton } from '@mui/material';
import { CiEdit } from "react-icons/ci";
import { AiOutlineDelete } from "react-icons/ai";
import SearchIcon from '@mui/icons-material/Search';
import { deleteRetailer, getRetailers } from '../../utils/api.retailer';
import AddNewRetailer from './AddNewRetailer';
import EditExistingRetailer from './EditExistingRetailer';
import AppConformationModel from '../../widgets/AppConformationModel';
import { errorMessage, successMessage } from '../../common/Toast';
import { MdAdd } from "react-icons/md";
import RotatingLoading from '../../widgets/RotatingLoading';

function ManageRetailer() {
  const headers = [
    'Sr.No', "Name", 'Phone No', "Location", 'Edit', 'Delete'
  ];

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [retailer_id, setRetailerId] = useState('')
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [is_add_new_retailer, setIsAddNewRetailer] = useState(false)
  const [is_success_response, setIsSuccessResponse] = useState(false)
  const [retailer_data_for_edit, setRetailerDataForEdit] = useState('')
  const [is_edit_conformation, setIsEditConformation] = useState(false)
  const [is_delete_conformation, setIsDeleteConformation] = useState(false)
  const [is_loading, setIsLoading] = useState(false)

  useEffect(() => {
    fetchRetailers();
  }, []);

  useEffect(() => {
    (async () => {
      if (is_success_response === true) {
        fetchRetailers()
        setIsSuccessResponse(false)
      }
    })()
  }, [is_success_response])

  const fetchRetailers = async () => {
    try {
      setIsLoading(true)
      const response = await getRetailers()
      if (response?.data?.data) {
        setData(response?.data?.data);
        setFilteredData(response?.data?.data);
      }
    } catch (error) {
      console.error('Error fetching retailers:', error);
    } finally {
      setIsLoading(false)
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    const query = event.target.value.toLowerCase();
    const filtered = data.filter(
      (row) =>
        row.retailer_name.toLowerCase().includes(query) ||
        row.phone_no.includes(query) ||
        row.location.toLowerCase().includes(query)
    );
    setFilteredData(filtered);
    setPage(0);
  };

  const onDeleteRetailer = async () => {
    try {
      const delete_response = await deleteRetailer(retailer_id)
      if (delete_response?.status === 200) {
        successMessage(delete_response?.data?.message)
        fetchRetailers()
      }
    } catch (error) {
      errorMessage(error?.response?.data?.message)
    } finally {
      setRetailerId('')
    }
  }

  const onDeleteConformation = (row_data) => {
    setRetailerId(row_data.id)
    setTitle(`Delete User (${row_data.retailer_name})`)
    setMessage(`Do you want delete this retailer ?`)
    setIsDeleteConformation(true)
  }

  const onEditConformation = (row_data) => {
    console.log(row_data);
    // setRetailerId(row_data.id)
    setIsEditConformation(true)
    setRetailerDataForEdit(row_data)
  }

  console.log(retailer_data_for_edit, 'retailer_data_for_edit');

  const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box className='m-a-30'>
      <AppConformationModel
        open={is_delete_conformation}
        setOpen={setIsDeleteConformation}
        title={title}
        message={message}
        onConformAction={onDeleteRetailer}
      />

      <AddNewRetailer
        open_model={is_add_new_retailer}
        setOpenModel={setIsAddNewRetailer}
        setSuccessResponse={setIsSuccessResponse}
      />

      <EditExistingRetailer
        open_model={is_edit_conformation}
        setOpenModel={setIsEditConformation}
        retailer_data={retailer_data_for_edit}
        setRetailerData={setRetailerDataForEdit}
        setSuccessResponse={setIsSuccessResponse}
      />

      <Box className='d-flex justify-between'>
        <Box>
          <TextField
            placeholder="Search by Retailer Name, Phone No, Location "
            value={searchQuery}
            onChange={handleSearchChange}
            size='small'
            InputProps={{
              style: { width: '250%' },
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box className='d-flex g-5 w-75'>
          <Button>Download CSV</Button>
          <Button onClick={() => setIsAddNewRetailer(true)} startIcon={<MdAdd />}>Add New Retailer</Button>
        </Box>
      </Box>
      <Box>
        {is_loading
          ? <Box>
            <RotatingLoading/>
          </Box>
          : <Box>
            <Box className='m-t-10'>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650, borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      {headers.map((header) => (
                        <TableCell className="border fw-bold" key={header} align="left">{header}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedData.map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row" align='left'>{(page * rowsPerPage) + index + 1}</TableCell>
                        <TableCell className="border" align='left'>{row.retailer_name}</TableCell>
                        <TableCell className="border" align="left">{row.phone_no}</TableCell>
                        <TableCell className="border" align="left">{row.location}</TableCell>
                        <TableCell className="border cursor" align="left"><CiEdit size={20} onClick={() => onEditConformation(row)} /></TableCell>
                        <TableCell className="border cursor" align="left"><AiOutlineDelete size={25} onClick={() => onDeleteConformation(row)} /></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </Box>
          </Box>}
      </Box>
    </Box>
  );
}

export default ManageRetailer;