import React, { useState, useEffect, useRef } from 'react';
import { useCompanyContext } from '../common/companyContext';
import { AppBar, Autocomplete, Box, TextField, Toolbar } from '@mui/material';
import { getCompanies } from '../utils/api.authentication';
import logo from '../assets/images/tracksure_logo.png';
import ProfileMenuList from './ProfileMenuList';
import { useNavigate } from 'react-router-dom';
import { getReportProcessInformation, getTabsModuleInformation } from '../utils/api.home';

function Header() {
  const navigate = useNavigate();
  const { updateSelectedCompany } = useCompanyContext();
  const [selectedTab, setSelectedTab] = useState('Home');
  const [selectedCompanyName, setSelectedCompanyName] = useState('');
  const [companyList, setCompanyList] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isReportDropdownOpen, setIsReportDropdownOpen] = useState(false);
  const [tabs_module_information, setTabsModuleInformation] = useState([]);
  const [process_report_information, setProcessReportInformation] = useState({});
  const dropdownRef = useRef(null);
  const reportDropdownRef = useRef(null);
  const menuTabsList = ['Home', 'Master', 'Reports'];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [company_response, tabs_module_response, report_process_response] = await Promise.all([
          getCompanies(),
          getTabsModuleInformation(),
          getReportProcessInformation(),
        ])
        if (company_response?.data?.data) {
          setCompanyList(company_response.data.data);
          const companyName = localStorage.getItem('company_name');
          if (companyName) {
            updateSelectedCompany(companyName);
            setSelectedCompanyName(companyName);
          }
        }

        if (tabs_module_response?.data?.data) {
          setTabsModuleInformation(tabs_module_response.data.data);
        }

        if (report_process_response?.data?.data) {
          let process_types = [];
          const process_map = new Map();
          for (let item of report_process_response.data.data) {
            // Process Type
            if (!process_types.includes(item.process)) {
              process_types.push(item.process);
            }
            // Report Type
            if (process_map.has(item.process)) {
              process_map.get(item.process)?.push(item.report);
            } else {
              process_map.set(item.process, [item.report]);
            }
          }
          let report_types = Array.from(process_map.entries()).reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {});
          setProcessReportInformation({
            process_types: process_types,
            report_types: report_types
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [updateSelectedCompany]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (dropdownRef.current && !dropdownRef.current.contains(event.target)) ||
        (reportDropdownRef.current && !reportDropdownRef.current.contains(event.target))
      ) {
        setIsDropdownOpen(false);
        setIsReportDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);

    if (tab === 'Home') {
      navigate(selectedCompanyName === 'prompt' ? '/dashboard/home' : `/dashboard/company/${selectedCompanyName}`);
    }

    setIsDropdownOpen(tab === 'Master' ? !isDropdownOpen : false);
    setIsReportDropdownOpen(tab === 'Reports' ? !isReportDropdownOpen : false);
  };

  const handleRouteNavigate = (routeName) => {
    const routes = {
      'Manage Users': '/dashboard/users',
      'Manage Drivers': '/dashboard/drivers',
      'Manage Retailers': '/dashboard/retailers',
      'Add Lines': '/dashboard/lines',
      'AddCompany': '/dashboard/add_company',
      'Add Shifts': '/dashboard/shifts',
      'Crate Search Engine': '/dashboard/crate_search_engine',
      'Display of Bill': '/dashboard/display_of_bill',
      'Alerts': '/dashboard/alerts',
      'Tracker': '/dashboard/tracker',
      'Download Crate Log': '/dashboard/download_crate_log',
      'Display of Delivery Challan': '/dashboard/display_of_delivery_challan',
      'Machine Configuration': '/dashboard/machine_configuration',
      'Add Manual Data': '/dashboard/manual_data_entry',
    };
    if (routes[routeName]) {
      navigate(routes[routeName]);
    }
  };

  const handleReportNavigate = (report_name) => {
    navigate('/dashboard/reports', { state: { report_name, report_types: process_report_information.report_types } });
  };

  const handleCompanyChange = (event, newValue) => {
    if (newValue) {
      localStorage.setItem('company_name', newValue);
      updateSelectedCompany(newValue);
      setSelectedCompanyName(newValue);
      setSelectedTab('Home');
      navigate(newValue === 'prompt' ? '/dashboard/home' : `/dashboard/company/${newValue}`);
    }
  };

  return (
    <div>
      <header style={{ color: 'white' }}>
        <AppBar className="Header-Background" position="fixed">
          <Toolbar className="d-flex justify-between">
            <Box className="d-flex justify-start g-5">
              <Box className="border-right">
                <img src={logo} className="m-r-10" alt="logo" height={40} />
              </Box>
              <Box className="d-flex">
                {menuTabsList.map((tab) => (
                  <div key={tab} onClick={() => handleTabClick(tab)}>
                    <p className={`p-l-20 m-t-10 fs-16 cursor ${selectedTab === tab ? 'selected' : 'deselected'}`}>
                      {tab}
                    </p>
                    {tab === 'Master' && isDropdownOpen && (
                      <div ref={dropdownRef} className="dropdown-menu b-r-10">
                        <div
                          style={{
                            display: tabs_module_information?.tabs.length > 6 ? 'grid' : 'block',
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            gap: '5px'
                          }}
                        >
                          {tabs_module_information?.tabs?.length > 0 && tabs_module_information?.tabs.map((tab, index) => (
                            <p key={index} onClick={() => handleRouteNavigate(tab.title)} className='cursor p-1'>
                              {tab.title}
                            </p>
                          ))}
                          {localStorage.getItem('company_name') === 'prompt' && (
                            <p onClick={() => handleRouteNavigate('AddCompany')} style={{ cursor: 'pointer' }}>
                              Add Company
                            </p>
                          )}
                        </div>
                      </div>)}
                    {tab === 'Reports' && isReportDropdownOpen && (
                      <div ref={reportDropdownRef} className="dropdown-menu">
                        {process_report_information?.process_types?.map((process) => (
                          <p key={process} onClick={() => handleReportNavigate(process)}>{process}</p>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </Box>
            </Box>
            <Box className="d-flex justify-end g-5">
              <Autocomplete
                value={selectedCompanyName}
                options={companyList}
                onChange={handleCompanyChange}
                sx={{ width: 300, backgroundColor: 'white', borderRadius: '10px' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      sx: { height: '42px' },
                    }}
                  />
                )}
              />
              <ProfileMenuList />
            </Box>
          </Toolbar>
        </AppBar>
      </header>
    </div>
  );
}

export default Header;