import React from 'react';
import Chart from 'react-apexcharts';

const BarGraph = ({ categories, series }) => {
    // const colors = ["#A5D7FF"];
    const colors = ['#A5D7FF', '#6CA0DC'];
    const options = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false
            },
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    const seriesValue = config.w.config.series[config.seriesIndex].data[config.dataPointIndex];
                    const category = config.w.config.xaxis.categories[config.dataPointIndex];
                    alert(`Selected data point: Category - ${category}, Value - ${seriesValue}`);
                },
                dataPointMouseEnter: function (event) {
                    event.target.style.cursor = "pointer";
                }
            },
        },
        grid: {
            show: false
        },
        plotOptions: {
            bar: {
                horizontal: true,
                // distributed: true,
                dataLabels: {
                    position: 'top',
                }
            },
        },
        dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
                fontSize: '10px',
                colors: ['#fff'],
            },
        },
        xaxis: {
            // categories: [2001, 2002, 2003, 2004, 2005],
            categories: categories,
        },
    };

    // const series = [
    //     { name: 'Count', data: [210, 55, 41, 64, 22] },
    // ];

    return <Chart options={options} series={series} type="bar" height={240} width={450} />;
};

export default BarGraph;
