import api from './interceptor'

export const registerUser = async (register_data) => {
    try {
        console.log(register_data);
        const response = await api.post(`user/v4/register`, register_data);
        return response;
    } catch (error) {
        throw error;
    }
}

export const loginUser = async (register_data) => {
    try {
        register_data.version = "v4"
        const response = await api.post(`authenticate/v1/login`, register_data);
        return response;
    } catch (error) {
        throw error;
    }
}

export const logoutUser = async () => {
    try {
        const response = await api.post(`authenticate/v1/revoke-token`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getCompanies = async () => {
    try {
        const response = await api.get(`input/home/company`);
        return response;
    } catch (error) {
        throw error;
    }
}