import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Container, Grid, TablePagination, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, Box, Modal } from '@mui/material';
import { MdAdd } from "react-icons/md";
import { getLines, addLine, deletLine } from '../../utils/api.user';
import { errorMessage, successMessage } from '../../common/Toast';
import { AiOutlineDelete } from "react-icons/ai";
import AppConformationModel from '../../widgets/AppConformationModel';
import Paper from '@mui/material/Paper';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    border: '2px solid aliceblue',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

const ManageLines = () => {
    const headers = [ 'Sr.No', "Name", 'Line ID', 'Process', 'Delete' ];
    const [lineName, setLineName] = useState('');
    const [lineType, setLineType] = useState('');
    const [line_data, setLineData] = useState([]);
    const [is_open_add_line, setIsOpenAddLine] = useState(false);
    const [is_delete_conformation, setIsDeleteConformation] = useState(false)
    const [line_id, setLineId] = useState('');
    const [title, setTitle] = useState('')
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [message, setMessage] = useState('')

    useEffect(() => {
        (async () => {
            onGetAllLinesData();
        })()
    }, [])

    const onGetAllLinesData = async () => {
        try {
            const response = await getLines();
            setLineData(response.data.data);
        } catch (error) {
            console.error(error);
        }
    }

    const addNewLine = async (event) => {
        event.preventDefault();
        try {
            const line_data = {
                line_name: lineName,
                line_type: lineType,
            };
            const response = await addLine(line_data);
            console.log(response);
            if (response.status === 200) {
                successMessage(response.data.message);
                handleClosePopUp();
                onGetAllLinesData();
            }
            setLineName('');
            setLineType('');
        } catch (error) {
            errorMessage(error.response.data.message);
        }
    };

    const onHandleDeleteConformation = (row_data) => {
        setLineId(row_data.id);
        setTitle(`Delete Line (${row_data.line})`)
        setMessage(`Do you want delete this line ?`)
        setIsDeleteConformation(true);
    }

    const onDeleteLine = async () => {
        try {
            const delete_response = await deletLine(line_id)
            if (delete_response?.status === 200) {
                successMessage(delete_response?.data?.message)
                onGetAllLinesData()
            }
        } catch (error) {
            errorMessage(error?.response?.data?.message)
        } finally {
            setLineId('')
        }
    }

    const handleClosePopUp = () => {
        setIsOpenAddLine(false);
    }

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Container>
            <AppConformationModel
                open={is_delete_conformation}
                setOpen={setIsDeleteConformation}
                title={title}
                message={message}
                onConformAction={onDeleteLine}
            />

            <div style={{ marginTop: '2rem' }}>
                <Box className="d-flex justify-between">
                    <p><b>Lines</b></p>
                    <Button onClick={() => setIsOpenAddLine(true)} startIcon={<MdAdd />}>Add New Line</Button>
                </Box>
                <Box className='m-t-10'>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650, borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    {headers.map((header) => (
                                        <TableCell className="border fw-bold" key={header} align="left">{header}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {line_data && line_data.map((row, index) => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row" align='left'>{(page * rowsPerPage) + index + 1}</TableCell>
                                        <TableCell className="border" align='left'>{row.line}</TableCell>
                                        <TableCell className="border" align="left">{row.id}</TableCell>
                                        <TableCell className="border" align="left">{row.process}</TableCell>
                                        <TableCell className="border cursor" align="left"><AiOutlineDelete size={25} onClick={() => onHandleDeleteConformation(row)} /></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={line_data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                </Box>
            </div>

            <Modal
                open={is_open_add_line}
                onClose={handleClosePopUp}
            >
                <Box sx={style}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h6"><b>Add Lines</b></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <form onSubmit={addNewLine}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="line_name"
                                    name="line_name"
                                    value={lineName}
                                    onChange={(e) => setLineName(e.target.value)}
                                    placeholder="Line Name"
                                    required
                                />
                                <FormControl fullWidth className='m-t-20'>
                                    <InputLabel id="line_type_label">Line Type</InputLabel>
                                    <Select
                                        labelId="line_type_label"
                                        id="line_type"
                                        name="line_type"
                                        value={lineType}
                                        onChange={(e) => setLineType(e.target.value)}
                                        label="Line Type"
                                        required
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        <MenuItem value="PRODUCTION">PRODUCTION</MenuItem>
                                        <MenuItem value="DISPATCH">DISPATCH</MenuItem>
                                    </Select>
                                </FormControl>
                                <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                                    <Button type="submit" variant="contained" size="large">Add Line</Button>
                                </div>
                            </form>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Container>
    );
};

export default ManageLines