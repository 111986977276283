import { Button, FormControl, Input, InputAdornment, TextField } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { errorMessage, successMessage } from '../common/Toast';
import logo from '../assets/images/tracksure.png'
import logo1 from '../assets/images/login-bg.png'
import { loginUser, registerUser } from '../utils/api.authentication';

function Login() {
  const navigate = useNavigate()
  const [phone_number, setPhoneNumber] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [confirm_password, setConfirmPassword] = React.useState('')
  const [is_login_register, setIsLoginRegister] = React.useState(true)

  const onLoginSubmit = async () => {
    try {
      const login_response = await loginUser({ phone_no: '+91' + phone_number, password: password })
      if (login_response?.status === 200) {
        successMessage('Login Successfully')
        localStorage.setItem('company_name', 'prompt')
        localStorage.setItem('role', login_response.data.data.role)
        localStorage.setItem('name', login_response.data.data.username)
        localStorage.setItem('company_id', login_response.data.data.company_id)
        localStorage.setItem('token', login_response.data.data.jwtToken)
        navigate('/dashboard/home')
      }
    } catch (error) {
      errorMessage(error?.response?.data?.message)
    } finally {
      // setLoading(false)
    }
  }

  const onRegisterSubmit = async () => {
    if (password !== confirm_password) {
      errorMessage('Password does not match')
      return
    }
    try {
      // setLoading(true)
      const register_response = await registerUser({ phone_no: '+91' + phone_number, password: password })
      if (register_response?.status === 200) {
        successMessage('Register Successfully')
        setIsLoginRegister(true)
      }
    } catch (error) {
      errorMessage(error?.response?.data?.message)
    } finally {
      // setLoading(false)
    }
  }

  const onHandleLoginRegister = () => {
    setIsLoginRegister(!is_login_register)
    setPhoneNumber('')
    setPassword('')
    setConfirmPassword('')
  }

  return (
    <div className='APP-background'>
      <div className='d-flex justify-start flex-direction-row'>
        <div className='w-35 bg-blue h-100vh d-flex justify-between flex-direction-column'>
          <div className='d-flex justify-center'>
            <img src={logo} alt="logo" className='w-80' height={250} />
          </div>
          <div className='d-flex justify-center'>
            <img src={logo1} alt="logo" className='w-80' height={400} />
          </div>
        </div>

        <form className='w-65 h-100vh' onSubmit={(e) => {
          e.preventDefault();
          is_login_register ? onLoginSubmit() : onRegisterSubmit()
        }} >
          <div className='h-100vh d-flex justify-center align-center flex-direction-column'>
            <div className='d-flex flex-direction-column justify-center align-center'>
              <h3 className='APP-color'>Login</h3>
              <p>Enter your mobile number to login in your account</p>
            </div>
            <br></br>
            <div>
              <FormControl variant="standard">
                <Input
                  required
                  label="Phone Number"
                  className='w-400px m-t-20'
                  placeholder='Enter Phone Number'
                  value={phone_number}
                  type='number'
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">+91</InputAdornment>
                  }
                />
              </FormControl>
            </div>
            <div>
              <TextField
                required
                className='w-400px m-t-20'
                // label="Password"
                placeholder='Enter Password'
                onChange={(e) => setPassword(e.target.value)}
                variant="standard"
                type='password'
                value={password} />
            </div>
            {!is_login_register && <div>
              <TextField
                required
                className='w-400px m-t-20'
                placeholder="Confirm Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                variant="standard"
                type='password'
                value={confirm_password}
              />
            </div>}
            <Button className='white APP-border w-400px m-t-20 bg-blue' variant="contained" type='submit'><b>{is_login_register ? 'Login' : 'Register'}</b></Button>
            <p className="text-center w-100">{is_login_register ? 'Don\'t have an account?' : 'Already have an account?'} <Button className='dark-blue APP-border' onClick={onHandleLoginRegister}><b>{is_login_register ? 'Register' : 'Login'}</b></Button></p>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login