export const report_options = {
    "Report": {
        "Empty": [
            { report_name: "Reception Log" },
            { report_name: "Reception Summary" },
            { report_name: "Vehicle wise Crate Receipt Log" },
            { report_name: "Verification Report" }
        ],
        "Production": [
            { report_name: "Production Log" },
            { report_name: "Production Summary" },
            { report_name: "Demand Summary" }
        ],
        "Dispatch": [
            { report_name: "Dispatch Log" },
            { report_name: "Dispatch Summary" },
            { report_name: "Vehicle wise Dispatch Log" },
            { report_name: "Verification Report" }
        ],
        "Product Reception Dock": [
            { report_name: "Product Receipt Log" },
            { report_name: "Product Receipt Summary" },
            { report_name: "Vehicle wise Product Receipt Log" }
        ],
        "Retailer": [
            { report_name: "Retailer Log" },
            { report_name: "Retailer Summary" }
        ],
        "Scrap": [
            { report_name: "Scrap crates log" }
        ],
        "Leaky": [
            { report_name: "Leaky crates log" }
        ],
        "New": [
            { report_name: "New crates log" }
        ],
        "All Crates Ids": [
            { report_name: "Crates Status log" }
        ]
    }
}

export const tags = [
    {tag_type: "With"},
    {tag_type: "Without"}
]

export const devices = [
    {device_type: "Android Application"},
    {device_type: "Industrial Camera"}
]