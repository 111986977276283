import { Box, Card, Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PieChart from '../../charts/PieChart'
import CompanyStatusTable from './CompanyStatusTable'
import { useNavigate } from 'react-router-dom'
import TotalInformation from './TotalInformation'
import BarGraph from '../../charts/BarGraph'
import RecentAlertTable from './RecentAlertTable'
import { getAlertCounts, getAllCompanyStatus } from '../../utils/api.home'
import { SkeletonBodyLoading, SkeletonTableLoading, SkeletonTopLoading } from '../../widgets/SkeltonEffect'

function Home() {
  const navigate = useNavigate();
  const [company_list, setCompanyList] = useState([]);
  const [recent_data, setRecentData] = useState([]);
  const [is_loading, setIsLoading] = useState(false);
  const [company_status, setCompanyStatus] = useState({
    total_company: 0,
    active_company: 0,
    inactive_company: 0,
    out_of_service_company: 0
  });
  const [series, setSeries] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const [response, alert_response] = await Promise.all([
        getAllCompanyStatus(),
        getAlertCounts()
      ]);
      
      // recent data
      if(alert_response?.data?.data) {
        setRecentData(alert_response.data.data);
      }

      // company status
      const { data } = response;
      if (data) {
        const { company_status, total_company, out_of_service } = data.data;

        const { online, offline } = company_status.reduce(
          (acc, company) => {
            company.active ? acc.online++ : acc.offline++;
            return acc;
          },
          { online: 0, offline: 0 }
        );

        setCompanyList(company_status);
        setCompanyStatus({
          total_company,
          active_company: online,
          inactive_company: offline,
          out_of_service_company: out_of_service
        });
        setSeries([online, offline]);
      }
      setIsLoading(false);
    })()
  }, [])

  const onHandleShowAllCompanies = () => {
    navigate('/dashboard/companies')
  }

  return (
    <Box className='m-a-30'>
      {is_loading
        ? <Box>
          <SkeletonTopLoading />
          <SkeletonBodyLoading />
          <SkeletonTableLoading />
        </Box>
        : <Box>
          <Box>
            <Box>
              <TotalInformation />
            </Box>
            <Box className='d-flex g-5 m-t-20'>
              <Card>
                <Box>
                  <h3 className='m-l-20 p-10'>Company</h3>
                  <Divider />
                  <Box className='d-flex g-3' sx={{ height: '250px' }}>
                    <Box className="p-14">
                      <PieChart title={['online', 'offline']} series={series} />
                    </Box>
                    <Box className='d-flex justify-center align-center'>
                      <Box sx={{ padding: '0px 50px 0px 10px' }}>
                        <div className='w-100 APP-COLOR text-center'><p className='fs-30 p-b-8 blue underline cursor' onClick={onHandleShowAllCompanies}>{company_status.total_company}</p></div>
                        <p className='p-b-5 fs-22 APP-COLOR APP-FONT'><span>Total Company</span>&nbsp;<span className='dark-blue underline cursor'></span></p>
                        <p className='p-b-5 APP-FONT'><span>Online</span>:&nbsp;<span className='dark-blue underline cursor'>{company_status.active_company}</span></p>
                        <p className='p-b-5 APP-FONT'><span>Offline</span>:&nbsp;<span className='dark-blue underline cursor'>{company_status.inactive_company}</span></p>
                        <p className='p-b-5 APP-FONT'><span>Out of service</span>:&nbsp;<span className='dark-blue underline cursor'>{company_status.out_of_service_company}</span></p>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Card>

              <Card>
                <Box>
                  <h3 className='m-l-20 p-10'>Alert</h3>
                  <Divider />
                  <Box sx={{ height: '250px', width: '500px', padding: '0px 0px 0px 0px' }}>
                    <BarGraph categories={
                      ['Camera Physical Remove', 'Weight Data Zero', 'Missing', 'Dublicate', 'unidentified']
                    } series={[{ name: 'Count', data: [21, 55, recent_data.missing_count, recent_data.duplicate_count, recent_data.unidentified_count] },]} />
                  </Box>
                </Box>
              </Card>

              <Card>
                <Box>
                  <h3 className='m-l-20 p-10'>Recent Alert</h3>
                  <Divider />
                  <Box className='d-flex g-3' sx={{ height: '250px', width: '500px' }}>
                    <Box sx={{ height: '250px', width: '500px' }}>
                      <RecentAlertTable />
                    </Box>
                  </Box>
                </Box>
              </Card>

            </Box>
            <Box className='m-t-10'>
              <h3 className='p-10 APP-FONT'>Current Prompt Tracksure Status</h3>
              <CompanyStatusTable data={company_list} />
            </Box>
          </Box>
        </Box>
      }
    </Box>
  )
}

export default Home