import React from 'react'
import { Route, Routes } from "react-router-dom";
import Home from '../views/home/Home';
import Header from "../header/Header";
import Company from '../views/company/Company';
import CompanyAnalytics from '../views/home/CompanyAnalytics';
import CompanyDetails from '../views/company/CompanyDetails';
import ManageLines from '../views/lines/ManageLines';
import ManageUser from '../views/users/ManageUser';
import ManageRetailer from '../views/retailers/ManageRetailer';
import Report from '../views/reports/Report';
import CrateSearchEngine from '../views/CrateSearchEngine';
import ManageDriver from '../views/drivers/ManageDriver';
import AddCompany from '../views/AddCompany'
import ManageShift from '../views/shifts/ManageShift';
import Alert from '../views/alert/Alert';
import Tracker from '../views/tracker/Tracker';
import DownloadCrateLog from '../views/download_crate_log/DownloadCrateLog';
import DisplayDeliveryChallan from '../views/display_delivary_challan/DisplayDeliveryChallan';
import MachineConfiguration from '../views/machine_configuration/MachineConfiguration';
import DisplayBill from '../views/display_bill/DisplayBill';
import AddManualData from '../views/manual_data_entry/AddManualData';

function Dashboard() {
    return (
        <div className='APP-BACKGROUND container'>
            <Header />
            <div className="main-content">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/companies" element={<Company />} />
                    <Route path="/analytics" element={<CompanyAnalytics />} />
                    <Route path="/company/:company" element={<CompanyDetails />} />
                    <Route path="/users" element={<ManageUser />} />
                    <Route path="/drivers" element={<ManageDriver />} />
                    <Route path='/retailers' element={<ManageRetailer />} />
                    <Route path='/add_company' element={<AddCompany />} />
                    <Route path='/lines' element={<ManageLines />} />
                    <Route path='/shifts' element={<ManageShift />} />
                    <Route path='/reports' element={<Report />} />
                    <Route path='/crate_search_engine' element={<CrateSearchEngine />} />
                    <Route path='/alerts' element={<Alert />} />
                    <Route path='/tracker' element={<Tracker/>} />
                    <Route path='/download_crate_log' element={<DownloadCrateLog/>} />
                    <Route path='/display_of_delivery_challan' element={<DisplayDeliveryChallan/>} />
                    <Route path='/display_of_bill' element={<DisplayBill/>} />
                    <Route path='/machine_configuration' element={<MachineConfiguration/>} />
                    <Route path='/manual_data_entry' element={<AddManualData/>} />  
                </Routes>
            </div>
        </div>
    )
}

export default Dashboard