import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, Typography, TablePagination } from '@mui/material';
import { getOneCompanyDetails } from '../../utils/api.home';
import { SkeletonTopLoading } from '../../widgets/SkeltonEffect';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DuplicateCrateLogHeaders, DuplicateCrateLogTableRows, MissingCrateLogHeaders, MissingCrateLogTableRows, UnidentifiedCrateLogHeaders, UnidentifiedCrateLogTableRows } from '../../TableSetting';
import { getDuplicateCrateData, getMissingCrateData, getTotalNotificationCount, getUnidentifiedCrateData } from '../../utils/api.reports';
import RotatingLoading from '../../widgets/RotatingLoading';

function Alert() {
  const [cardData, setCardData] = useState([]);
  const [category, setCategory] = useState('missing');
  const [is_loading, setIsLoading] = useState(true);
  const [is_circular_loading, setIsCircularLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  let filter_data = {};

  useEffect(() => {
    (async () => {
      try {
        const notification_response = await getTotalNotificationCount();
        if (notification_response?.data?.data) {
          const data = notification_response?.data?.data
          setCardData([
            { title: 'Total No. Of Missing Crates', data: data.missing_crate_count ? data.missing_crate_count : 0 },
            { title: 'Total No. Of Unidentified Crates', data: data.unidentified_crate_count ? data.unidentified_crate_count : 0 },
            { title: 'Total No. Of Duplicate Crates', data: data.duplicate_crate_count ? data.duplicate_crate_count : 0 },
          ])
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setIsCircularLoading(true);
      filter_data.sort_attribute = 'date';
      filter_data.sort_direction = 'desc';
      filter_data.page_index = +page;
      filter_data.page_size = +rowsPerPage;

      switch (category) {
        case 'missing': {
          const response = await getMissingCrateData(filter_data);
          setTableHeader(MissingCrateLogHeaders)
          setTotalRows(response.data.data.total_length);
          setTableRows(MissingCrateLogTableRows(response.data.data.data));
          break;
        }
        case 'unidentified': {
          const response = await getUnidentifiedCrateData(filter_data);
          setTableHeader(UnidentifiedCrateLogHeaders)
          setTotalRows(response.data.data.total_length);
          setTableRows(UnidentifiedCrateLogTableRows(response.data.data.data));
          break;
        }
        case 'duplicate': {
          const response = await getDuplicateCrateData(filter_data);
          setTableHeader(DuplicateCrateLogHeaders)
          setTotalRows(response.data.data.total_length);
          setTableRows(DuplicateCrateLogTableRows(response.data.data.data));
          break;
        }
        default:
          break;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsCircularLoading(false);
    }
  }, [page, rowsPerPage, category, filter_data]);

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, category]);

  const handlePageChange = (event, newPage) => {
    if (newPage >= 0) {
      setPage(newPage + 1);
    }
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onHandleCategoryChange = (event) => {
    setCategory(event.target.value);
    setPage(1);
    setRowsPerPage(10);
  };

  return (
    <Box className='m-a-30'>
      <Box>
        {is_loading ?
          <SkeletonTopLoading />
          : <Box
            sx={{
              backgroundColor: '#6ca0dc',
              padding: 2,
              borderRadius: 2,
              width: '100%',
            }}
          >
            <Grid container spacing={2}>
              {cardData.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={index}
                >
                  <Box
                    sx={{
                      textAlign: 'left',
                      borderRight: (index + 1) % 3 !== 0 ? '1px solid white' : 'none',
                      width: '100%',
                    }}
                  >
                    <Typography variant="h6" className='white' >
                      {item.title}
                    </Typography>
                    <Typography variant="h5" className='fw-bold white'>
                      {item.data}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        }
      </Box>
      <Box>
        <div>
          <select
            value={category}
            className='w-30 m-t-20 p-10 b-r-7'
            onChange={onHandleCategoryChange}
          >
            <option value={'missing'}>Missing</option>
            <option value={'duplicate'}>Dublicate</option>
            <option value={'unidentified'}>Unidentified</option>
          </select>
        </div>

        <Box>
          {is_circular_loading
            ? <RotatingLoading />
            : <Box className='m-t-20'>
              <TableContainer component={Paper} style={{ maxHeight: '70%', overflowY: 'auto' }}>
                <Table stickyHeader sx={{ minWidth: 650, maxHeight: 10, overflowY: 'auto' }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      {tableHeader.map((header) => (
                        <TableCell key={header} align="left" className='cursor border fw-bold APP-COLOR-2'>{header}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableRows && tableRows.length
                      ? tableRows
                      : <TableRow>
                        <TableCell className='p-10' colSpan={tableHeader.length} align="center">No Records Found</TableCell>
                      </TableRow>}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </Box>}
        </Box>
      </Box>
    </Box>
  );
}

export default Alert