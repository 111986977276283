import React, { useEffect, useState } from 'react';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import PieChart from '../../charts/PieChart';
import RecentAlertTable from '../home/RecentAlertTable';
import { getCompanyProfileCount, getCompanyTotalData, getDevicesInformation, getTabsModuleInformation } from '../../utils/api.home';
import { useCompanyContext } from '../../common/companyContext';
import BarGraph from '../../charts/BarGraph';
import { SkeletonBodyLoading, SkeletonTopLoading } from '../../widgets/SkeltonEffect';

function CompanyDetails() {
    const { selectedCompany } = useCompanyContext();
    const [device_information, setDeviceInformation] = useState({});
    const [company_today_scan_data, setCompanyTodayScanData] = useState([]);
    const user_info_series = ['Users', "Drivers", "Vehicle", "Reatilers", "Route"];
    const [user_info_series_data, setUserInfoSeriesData] = useState([]);
    const [is_loading, setIsLoading] = useState(false);
    const [module_info, setModuleInfo] = useState('');

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const [today_company_info_response, device_response, company_profiles_response, tab_module_info_response] = await Promise.all([
                    getCompanyTotalData(),
                    getDevicesInformation(),
                    getCompanyProfileCount(),
                    getTabsModuleInformation()
                ]);

                if (company_profiles_response?.data?.data) {
                    const info = company_profiles_response.data.data;
                    setUserInfoSeriesData([{
                        name: 'Count',
                        data: [info.user_count, info.driver_count, info.driver_count, info.retailer_count, info.route_count]
                    }]);
                }

                if (device_response?.data?.data) {
                    setDeviceInformation(device_response.data.data);
                }
                setModuleInfo(tab_module_info_response?.data?.data?.module_info);
                if (today_company_info_response?.data) {
                    const res = today_company_info_response?.data
                    const data = tab_module_info_response?.data?.data?.module_info === 'production'
                        ? [
                            { title: 'Total No. of Scanned Crate', amount: res.TotalCratesForOnlyProduction },
                            { title: 'Total No. Of Rejected Crates', amount: res.TotalRejectedCratesCount },
                            { title: 'Total No. Of Crates Moved To cold Storage', amount: (res.TotalCratesForOnlyProduction - res.TotalRejectedCratesCount) },
                        ]
                        : [
                            { title: 'Total No. of Scanned Crate', amount: res.Totalcrates },
                            { title: 'Total No. Of Rejected Crates', amount: res.TotalRejectedCratesCount },
                            { title: 'Total No. of Inward Crates', amount: res.TotalInward },
                            { title: 'Total No. of Outward Crates', amount: res.TotalOutward },
                            { title: 'Total Difference in Crates', amount: res.TotalDifferenceInCrates },
                        ]
                    setCompanyTodayScanData(data);
                }
            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [selectedCompany]);

    return (
        <Box className='m-a-30'>
            {is_loading
                ? <Box>
                    <SkeletonTopLoading />
                    <SkeletonBodyLoading />
                </Box>
                : <Box>
                    {/* Part 1 */}
                    <Box
                        sx={{
                            backgroundColor: '#6ca0dc',
                            borderRadius: 2,
                            width: '100%',
                        }}
                    >
                        <Grid container spacing={2}>
                            {company_today_scan_data?.map((item, index) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={(module_info === 'production' ? 4 : 2.4)}
                                    key={index}
                                    sx={{
                                        borderBottom: index < (module_info === 'production' ? 3 : 5) ? '1px solid white' : 'none',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '20px 0px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            textAlign: 'left',
                                            borderRight: (index + 1) % (module_info === 'production' ? 3 : 5) !== 0 ? '1px solid white' : 'none',
                                            padding: (index) % (module_info === 'production' ? 3 : 5) === 0 ? '0px 0px 0px 20px' : 'none',
                                            width: '100%'
                                        }}
                                    >
                                        <Typography variant="h6" className='white' >
                                            {item.title}
                                        </Typography>
                                        <Typography variant="h5" className='fw-bold white'>
                                            {item.amount}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    {/* Part 2 */}
                    <Box>
                        <Box className='d-flex g-5 m-t-20'>
                            <Card>
                                <Box>
                                    <h3 className='m-l-20 p-10'>Device</h3>
                                    <Divider />
                                    <Box className='d-flex g-3' sx={{ height: '250px' }}>
                                        <Box className="p-14" style={{ width: '65%' }} >
                                            <PieChart title={['online', 'offline']} series={
                                                module_info === 'production'
                                                    ? [device_information?.no_of_online_line || 0, (device_information?.no_of_production_line - device_information?.no_of_online_line) || 0]
                                                    : [device_information?.no_of_online_line || 0, (device_information?.no_of_production_line + device_information?.no_of_dispatch_line - device_information?.no_of_online_line) || 0]
                                            } />
                                        </Box>
                                        <Box className='d-flex justify-center align-center' style={{ width: '35%' }}>
                                            <Box sx={{ padding: '0px 20px 0px 0px' }}>
                                                <div>
                                                    <div className='p-b-5 d-flex justify-between g-5 m-l-10'>
                                                        <p>Total Devices:</p>
                                                        <p>{module_info === 'production' ? device_information?.no_of_production_line : device_information?.no_of_production_line + device_information?.no_of_dispatch_line}</p>
                                                    </div>
                                                    <div className='p-b-5 d-flex justify-between g-5 m-l-10'>
                                                        <p>Online Devices:</p>
                                                        <p>{device_information?.no_of_online_line}</p>
                                                    </div>
                                                    <div className='p-b-5 d-flex justify-between g-5 m-l-10'>
                                                        <p>Production:</p>
                                                        <p>{device_information?.no_of_production_line}</p>
                                                    </div>
                                                    {module_info !== 'production' && <div className='p-b-5 d-flex justify-between g-5 m-l-10'>
                                                        <p>Dispatch:</p>
                                                        <p>{device_information?.no_of_dispatch_line}</p>
                                                    </div>}
                                                    <div className='p-b-5 d-flex justify-between g-5 m-l-10'>
                                                        <p>Out of service:</p>
                                                        <p>{device_information?.no_of_lines_in_maintenance || 0}</p>
                                                    </div>
                                                </div>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Card>

                            <Card>
                                <Box>
                                    <h3 className='m-l-20 p-10'>Alert</h3>
                                    <Divider />
                                    <Box className='d-flex g-3' sx={{ height: '250px', width: '500px' }}>
                                        <Box sx={{ height: '250px', width: '500px' }}>
                                            <RecentAlertTable />
                                        </Box>
                                    </Box>
                                </Box>
                            </Card>

                            <Card>
                                <Box>
                                    <h3 className='m-l-20 p-10'>User Info</h3>
                                    <Divider />
                                    <Box className='d-flex g-3' sx={{ height: '250px', width: '500px' }}>
                                        <BarGraph categories={user_info_series} series={user_info_series_data} />
                                    </Box>
                                </Box>
                            </Card>
                        </Box>
                    </Box>
                </Box>}
        </Box>
    );
}

export default CompanyDetails